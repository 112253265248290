import { FC, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
  createPath,
  updatePath,
  destroyPath,
  CustomFormItem,
  RequestData,
  Errors,
} from 'libs/apiClient/admin/customFormItems';
import { create, update, destroy } from 'libs/apiClient';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import CustomFormItemForm from './CustomFormItemForm';
import { useFetchCustomFormItems } from 'hooks/api/admin/customFormItems/useFetchCustomFormItems';

type Props = {
  entryFormId: number | string;
}

const CustomFormItems: FC<Props> = (props) => {
  const { entryFormId } = props;
  const formDataTemplate: RequestData = {
    fieldContext: 'text_field',
    isRequired: false,
    label: '',
    priority: 0,
    entryFormId: entryFormId,
    itemDetail: {}
  }
  const [formData, setFormData] = useState<RequestData>(formDataTemplate);
  const [errors, setErrors] = useState<Errors>({});
  const [isDisplayForm, setIsDisplayForm] = useState<boolean>(false);
  const [customFormItemId, setCustomFormItemId] = useState<string|number|null>(null);
  const { isLoading, customFormItems, fetch } = useFetchCustomFormItems(String(entryFormId))

  const onCreate = () => {
    setIsDisplayForm(true);
    setFormData(formDataTemplate);
    setCustomFormItemId(null)
    setErrors({});
  }

  const onUpdate = (item: CustomFormItem) => {
    setIsDisplayForm(true)
    setFormData({
      fieldContext: item.fieldContext,
      isRequired: item.isRequired,
      label: item.label,
      priority: item.priority,
      entryFormId: entryFormId,
      itemDetail: item.itemDetail
    })
    setCustomFormItemId(item.id);
    setErrors({});
  }

  const onSubmit = () => {
    const callback = (_res: Response) => {
      fetch()
      setIsDisplayForm(false);
      setFormData(formDataTemplate);
      setCustomFormItemId(null);
      setErrors({});
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }

    if (customFormItemId) {
      // update
      update(updatePath(customFormItemId), formData, 'customFormItem', callback, errCallback);
    } else {
      // create
      create(createPath, formData, 'customFormItem', callback, errCallback);
    }
  }

  const onDestroy = (id: number | string) => {
    const callback = (_res: Response) => {
      fetch()

      if (customFormItemId == id) {
        setIsDisplayForm(false);
        setFormData(formDataTemplate);
        setCustomFormItemId(null);
        setErrors({});
      }
    }

    const errCallback = (err: Errors) => {
      console.log(err)
    }

    destroy(destroyPath(id), 'message', callback, errCallback);
  }

  return (
    <>
      <PanelTitle title='カスタムフォームアイテム'>
        <Button variant="primary" onClick={ _e => onCreate() }>新規作成</Button>
      </PanelTitle>
      <Table className='mb-2'>
        <thead>
          <tr>
            <th>ID</th>
            <th>タイプ</th>
            <th>必須/任意</th>
            <th>見出し</th>
            <th>表示順</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          { !isLoading && (
            customFormItems.map(item => {
              return (
                <tr key={item.id}>
                  <td className={ `align-middle` } >{item.id}</td>
                  <td className={ `align-middle` } >{item.fieldContext}</td>
                  <td className={ `align-middle` } >{String(item.isRequired)}</td>
                  <td className={ `align-middle` } >{item.label}</td>
                  <td className={ `align-middle` } >{item.priority}</td>
                  <td className={ `align-middle`} >
                    <Button
                      variant='dark'
                      onClick={() => onUpdate(item)}
                    >
                      編集
                    </Button>
                    {/* <Button
                      variant="outline-info"
                      className="ml-3"
                      onClick={() => onDestroy(item.id)}
                    >
                      削除
                    </Button> */}
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </Table>
      {
        isDisplayForm && (
          <CustomFormItemForm
            formData={ formData }
            setFormData={ setFormData }
            errors={ errors }
            setErrors={ setErrors }
            onSubmit={ onSubmit }
            customFormItemId={ customFormItemId }
          />
        )
      }
    </>
  )
}

export default CustomFormItems;