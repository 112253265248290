import { useFetch } from 'hooks/useFetch'

export type Area = {
  id: string,
  name: string,
  prefectures: {
    id: string,
    name: string
  }[]
}

type ResponseType = {
  areas: Area[]
}

export const useFetchAreas = (isImmediate = false) => {
  const path = 'api/v1/areas'
  return useFetch<ResponseType>(path, isImmediate)
}
