import React, { useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import Table from 'components/Table';
import { Button } from 'react-bootstrap';
import { index, Administrator } from 'libs/apiClient/admin/administrators'
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import { PermissionGroup } from 'libs/apiClient/admin/permissionGroups';
import {
  newAdminsPath,
  editAdminsPathCreator
} from 'routes/admins/path';
import Forbidden from 'pages/forbidden';

const Index = () => {
  const history = useHistory()
  const [adminList, setAdminList] = useState<Administrator[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }

    index(setAdminList, forbiddenCallback);
  }, []);

  const Page = () => {
    return (
      <Layout>
        <div className="d-flex pb-3">
          <h1 className="mr-auto">管理者一覧</h1>
          <Button onClick={() => history.push(newAdminsPath)}>新規追加</Button>
        </div>
        <Panel>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Permission Group</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {
                adminList.map(admin => {
                  return (
                    <tr key={nanoid()}>
                      <td className="align-middle" >{admin.id}</td>
                      <td className="align-middle" >{admin.name}</td>
                      <td className="align-middle" >{admin.email}</td>
                      <td className="align-middle" >{admin.permissionGroup ? admin.permissionGroup.name : ''}</td>
                      <td className="align-middle" >
                        <Button variant='dark' onClick={() => history.push(editAdminsPathCreator(admin.id))}>編集</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Index;