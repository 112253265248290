import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { useHistory } from 'react-router';
import { showTagsPathCreator } from 'routes/tags/path';
import { show, Tag, update, UpdateData, UpdateErrors, UpdateResponse } from 'libs/apiClient/admin/tags';
import TagForm from '../components/TagForm';
import { RouteComponentProps } from 'react-router-dom';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Edit: FC<urlProps> = (props) => {
  const history = useHistory()
  const [updateData, setUpdateData] = useState<UpdateData>({ name: '' });
  const [errors, setErrors] = useState<UpdateErrors>({ name: [] });
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const tagId = props.match.params.id;

  const forbiddenCallback = () => { setIsForbidden(true); }

  useEffect(() => {
    show(tagId, (tag: Tag) => { setUpdateData({ name: tag.name }) }, forbiddenCallback)
  }, []);

  const onSubmit = async () => {
    const callback = (res: UpdateResponse) => {
      history.push(showTagsPathCreator(res.id))
    }

    const errCallback = (err: UpdateErrors) => {
      setErrors(err);
    }

    await update(tagId, updateData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="タグ編集" />
        <Panel className="col-6">
          <TagForm
            formData={updateData}
            setFormData={setUpdateData}
            errors={errors}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Edit;