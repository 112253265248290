import { useCallback, useMemo } from 'react';
import { post } from 'libs/fetch';

export const useEvent = () => {
  const createEvent = useCallback(async () => {
    const response = await post('api/v1/admin/sales_forces/events', {})
    if (response.ok) {
      return { result: true }
    }

    return { result: false }
  }, []);

  return useMemo(() => ({ createEvent }),[ createEvent ])
}
