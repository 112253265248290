import { FC, ReactNode } from 'react';
import { Navbar, Nav, Button, Container, Row, Col } from 'react-bootstrap';
import { destroy } from 'libs/fetch';
import { useHistory } from 'react-router-dom';
import Sidebar from "components/Sidebar";
import style from './Layout.module.scss';

type LayoutProps = {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = (props) => {
  const history = useHistory();
  const { children } = props;

  const onSubmit = async () => {
    const response = await destroy('api/v1/admin/sign_out')
    if (response.ok) {
      history.push('/login');
    }
  }

  return(
    <>
      <Container fluid className="p-0">
        <Navbar className={ `${style.header}` }>
          <Navbar.Brand href="/" className="mr-auto col-md-3 col-lg-2">就活Times</Navbar.Brand>
          <Button variant="outline-info" onClick={onSubmit} >Logout</Button>
        </Navbar>
      </Container>
      <Container fluid>
        <Row className={ style.main }>
          <Nav className={ `col-md-3 col-lg-2 ${style.sidebar}` }>
            <Sidebar />
          </Nav>
          <Col md={9} lg={10} className={ style.content }>
            { children }
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Layout;
