import { useState, useEffect } from 'react';
import { useUniversities } from './api/universities/useFetchUniversities'

type Option = {
  id: string | number,
  value: string | number,
  label: string
}

type UseUniversityOptions = () => {
  reloadUniversityOptions: (searchKey: string, degree: string) => void;
  universityOptions: Option[]
};

export const useUniversityOptions: UseUniversityOptions = () => {
  const { universities, fetchUniversities } = useUniversities();
  const [universityOptions, setUniversityOptions] = useState<Option[]>([]);

  const reloadUniversityOptions = async (searchKey: string, degree: string) => {
    fetchUniversities(searchKey, degree)
  }

  useEffect(() => {
    if (universities) {
      const options = universities.map(university => (
        {
          id: university.id,
          value: university.id,
          label: university.name,
        }
      ))

      setUniversityOptions(options)
    }
  }, [universities])

  return {
    reloadUniversityOptions,
    universityOptions
  };
};
