import React from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';

const LoadSpinner = () => {
  return (
    <Container className='center'>
      <Row className='d-flex align-items-center justify-content-center'>
        <Spinner animation="border" className='mr-3'/>
        <span>loading ...</span>
      </Row>
    </Container>
  )
}

export default LoadSpinner;
