import { FC, Dispatch, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { RequestData, Errors } from 'libs/apiClient/admin/creatives';

type Props = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
  onSubmitFormData: (requestData: FormData) => void;
  thumbnail: string | undefined;
  setThumbnail: Dispatch<React.SetStateAction<string|undefined>>;
}

const CreativeForm: FC<Props> = (props) => {
  const { formData, setFormData, errors, onSubmitFormData, thumbnail, setThumbnail } = props;
  const onChange = (value: Omit<RequestData[keyof RequestData], 'thumbnail'>, name: keyof RequestData) => {
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData)
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFormData = { ...formData }
      newFormData.thumbnail = e.target.files[0];
      setFormData(newFormData)
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e?.target?.result) {
          setThumbnail(e.target.result as string);
        }
      };
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const isListedChange = (value: string) => {
    const isListed = value == 'true';
    onChange(isListed, 'isListed');
  }

  const onSubmit = (isPublished: boolean) => {
    const requestData = new FormData();
    requestData.append('creative[administrative_title]', formData.administrativeTitle);
    requestData.append('creative[memo]', formData.memo);
    requestData.append('creative[thumbnail]', formData.thumbnail ?? '');
    requestData.append('creative[title]', formData.title);
    requestData.append('creative[abstruct]', formData.abstruct);
    requestData.append('creative[body]', formData.body);
    requestData.append('creative[style]', formData.style);
    requestData.append('creative[is_listed]', String(formData.isListed));
    requestData.append('creative[is_published]', String(isPublished));
    requestData.append('creative[campaign_id]', String(formData.campaignId));

    onSubmitFormData(requestData)
  }

  return (
    <Form noValidate>
      <Form.Group controlId='administrativeTitle'>
        <Form.Label>管理用タイトル</Form.Label>
        <Form.Control
          type='text'
          name="name"
          defaultValue={ formData.administrativeTitle }
          onChange={ e => onChange(e.target.value, 'administrativeTitle') }
          isInvalid={ errors.administrativeTitle && errors.administrativeTitle?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.administrativeTitle?.map((err, i) => <p key={ `error-adminiatrativeTitle-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='memo'>
        <Form.Label>メモ(管理用)</Form.Label>
        <Form.Control
          as="textarea"
          name="memo"
          defaultValue={ formData.memo }
          onChange={ e => onChange(e.target.value, 'memo') }
          isInvalid={ errors.memo && errors.memo?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.memo?.map((err, i) => <p key={ `error-memo-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='title'>
        <Form.Label>タイトル</Form.Label>
        <Form.Control
          type='text'
          name="title"
          defaultValue={ formData.title }
          onChange={ e => onChange(e.target.value, 'title') }
          isInvalid={  errors.title && errors.title?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.title?.map((err, i) => <p key={ `error-title-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='thumbnail'>
        <Form.Label>サムネイル</Form.Label>
        <Form.Control
          type="file"
          placeholder="サムネイルを選択してください"
          accept=".jpg,.jpeg,.png"
          name="thumbnail"
          onChange={ onFileChange }
          isInvalid={ errors.thumbnail && errors.thumbnail?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.thumbnail?.map((err, i) => <p key={ `error-thumbnail-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
        { thumbnail && <Image className='col-6 mt-2' src={ thumbnail } thumbnail /> }
      </Form.Group>

      <Form.Group controlId='abstruct'>
        <Form.Label>概要</Form.Label>
        <Form.Control
          as="textarea"
          name="abstruct"
          defaultValue={ formData.abstruct }
          onChange={ e => onChange(e.target.value, 'abstruct') }
          isInvalid={ errors.abstruct && errors.abstruct?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.abstruct?.map((err, i) => <p key={ `error-abstruct-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='body'>
        <Form.Label>html</Form.Label>
        <Form.Control
          as="textarea"
          name="body"
          defaultValue={ formData.body }
          onChange={ e => onChange(e.target.value, 'body') }
          isInvalid={ errors.body && errors.body?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.body?.map((err, i) => <p key={ `error-body-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='style'>
        <Form.Label>css</Form.Label>
        <Form.Control
          as="textarea"
          name="style"
          defaultValue={ formData.style }
          onChange={ e => onChange(e.target.value, 'style') }
          isInvalid={ errors.style && errors.style?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.style?.map((err, i) => <p key={ `error-style-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='isListedRadio'>
        <Form.Label>デフォルト表示</Form.Label>
        <div className={ errors.isListed && errors.isListed?.length > 0 ? 'is-invalid' : ''}>
          <Form.Check
            inline
            type={ 'radio' }
            id='isListed'
            name='isListed'
            label='yes'
            value='true'
            checked={ formData.isListed }
            onChange={ e =>isListedChange(e.target.value) }
          />
          <Form.Check
            inline
            type={ 'radio' }
            id='isNotListed'
            name='isListed'
            label='no'
            checked={ !formData.isListed }
            value='false'
            onChange={ e =>isListedChange(e.target.value) }
          />
        </div>
        <Form.Control.Feedback type="invalid">
          { errors.isListed?.map((err, i) => <p key={ `error-isListed-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="button" variant='outline-secondary' className='mr-2' onClick={ _e => onSubmit(false) }>下書き</Button>
      <Button type="button" variant='outline-success' onClick={ _e => onSubmit(true) }>公開</Button>
    </Form>
  )
}

export default CreativeForm;