import New from 'pages/companies/campaigns/creatives/new';
// import Show from 'pages/companies/campaigns/creatives/show';
import Edit from 'pages/companies/campaigns/creatives/edit';

import {
  newCompanyCampaignCreativesPath,
  showCompanyCampaignCreativesPath,
  editCompanyCampaignCreativesPath
} from './path';

export const companyCampaignCreativesRoutes = [
  {
    name: "クリエイティブ作成",
    path: newCompanyCampaignCreativesPath,
    component: New,
    auth: true,
    visible: false
  },
  // {
  //   name: "クリエイティブ詳細",
  //   path: showCompanyCampaignCreativePath,
  //   component: Show,
  //   auth: true,
  //   visible: false
  // },
  {
    name: "クリエイティブ編集",
    path: editCompanyCampaignCreativesPath,
    component: Edit,
    auth: true,
    visible: false
  },
]

