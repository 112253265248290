import { VFC, Dispatch, SetStateAction, useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap';
import { nanoid } from 'nanoid'
import { SelectDetail, MultipleItem } from 'types/api/admin/customFormItems'

type SelectProps = {
  selectDetail: SelectDetail
  onChange: (data: SelectDetail) => void;
  // onAdd: (item: SelectItem) => void;
  // onRemove: (items: SelectItem[]) => void;
  errors: string[]
}

export const Select: VFC<SelectProps> = ({
  selectDetail,
  onChange,
  errors
}) => {
  const itemTemplate = (): MultipleItem => ({
    label: '',
    value: '',
    isDefault: false
  })

  const onChangePlaceholder = (value: string) => {
    const newSelectDetail = { ...selectDetail, placeholder: value }
    onChange(newSelectDetail)
  }

  const onChangeItem = (value: string | boolean, label: keyof MultipleItem, index: number) => {
    const newSelectDetail = {...selectDetail}
    const selectItem = {...newSelectDetail.items[index], [label]: value}
    newSelectDetail.items[index] = selectItem
    onChange(newSelectDetail)
  }

  const onAddItem = () => {
    const newSelectDetail = { ...selectDetail }
    newSelectDetail.items.push(itemTemplate())
    onChange(newSelectDetail)
  }

  const onDeleteItem = (index: number) => {
    const newSelectDetail = { ...selectDetail }
    newSelectDetail.items.splice(index, 1)
    console.log(newSelectDetail)
    onChange(newSelectDetail)
  }

  return (
    <>
      <Form.Group>
        <div className={ errors && errors.length > 0 ? 'is-invalid' : '' }></div>
        <Form.Control.Feedback type="invalid">
          { errors.map((err: string, i: number) => <span className='d-block mb-1' key={ `error-customSelect-${i}` }>{ err }</span>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Control
          key={ `custom-form-placeholder` }
          type='text'
          name="placeholder"
          value={ selectDetail.placeholder }
          placeholder='プレースホルダー'
          onChange={ e => onChangePlaceholder(e.target.value) }
        />
      </Form.Group>

      { selectDetail.items.map((item, i) => {
        return (
          <div key={`mul-key-${i}`}>
            <Row>
              <Form.Group as={Col} md="3">
                <Form.Control
                  key={ `custom-form-label` }
                  type='text'
                  name="label"
                  value={ item.label }
                  placeholder='ラベル'
                  onChange={ e => onChangeItem(e.target.value, 'label', i) }
                />
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Form.Control
                  type='text'
                  name="value"
                  value={ item.value }
                  placeholder='値'
                  onChange={ e => onChangeItem(e.target.value, 'value', i) }
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="align-self-center" controlId={ `multiple-default-${nanoid(8)}` }>
                <Form.Check
                  type={ 'checkbox' }
                  name='isDefault'
                  checked={ item.isDefault }
                  label={ 'デフォルト' }
                  onChange={ e => onChangeItem(e.target.checked, 'isDefault', i) }
                />
              </Form.Group>

              { i > 0 && (
                <Form.Group as={Col} md="3" className="align-self-center" controlId='multiple-delete'>
                  <Button variant="light" onClick={ _e => onDeleteItem(i)}>削除</Button>
                </Form.Group>
              )}
            </Row>
          </div>
        )
      })}

      <Button variant="secondary" onClick={ _e => onAddItem() }>選択肢を追加</Button>
    </>
  )
}
