import { FC, useState } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import { Button } from 'react-bootstrap';
import { useUserCsv } from 'hooks/api/users/csv/ussUserCsv';

const Index: FC = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createCsv } = useUserCsv();
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const onClick = async () => {
    setIsLoading(true);
    const { result } = await createCsv();
    setIsLoading(false);
    setIsComplete(true);
  }

  return (
    <Layout>
      <PageTitle title="CSV作成"/>
      <Panel>
        {isComplete ? (
          <>
            <span>
              csvの作成をしています。
              <br/>
              DLリンクが送信されるまでしばらくお待ちください。
            </span>
          </>
        ) : (
          <>
            <p>
              csv作成ボタンを押すとバックグラウンドでcsvが作成されます。
              <br/>
              csvが作成されるとメールでDLリンクが送信されます。
            </p>
            <Button
              disabled={isLoading}
              onClick={onClick}
            >
              CSV作成
            </Button>
          </>
        )}
      </Panel>
    </Layout>
  )
}

export default Index;
