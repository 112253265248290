import { FC, useEffect, useState, ReactNode } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import Selectize, { Item } from 'components/Selectize';
import DateTime from 'components/DateTime';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
  index as getEducationalBackgroundGroups,
  EducationalBackgroundGroup
} from 'libs/apiClient/admin/educationalBackgroundGroups';
import { index as getTags, Tag } from 'libs/apiClient/admin/tags';
import 'moment/locale/ja';
import { create, CreateData, CreateErrors, CreateResponse } from 'libs/apiClient/admin/campaigns';
import { newEducationalBackgroundGroupsPath } from 'routes/educationalBackgroundGroups/path';
import { newTagsPath } from 'routes/tags/path';
import { showCompaniesPathCreator } from 'routes/companies/path';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;
type CampaignType = 'inside' | 'outside' | 'custom';
type Year = {
  year: number;
  month: number;
};

const New: FC<urlProps> = (props) => {
  const history = useHistory()
  const [formData, setFormData] = useState<CreateData>({
    companyId: props.match.params.id,
    type: 'inside',
    name: '',
    memo: '',
    startAt: '',
    closeAt: '',
    customHtml: '',
    middlewareId: undefined,
    redirectUrl: undefined,
    graduationYears: [],
    tags: [],
    educationalBackgroundGroups: [],
  });
  const [errors, setErrors] = useState<CreateErrors>({
    companyId: [],
    type: [],
    name: [],
    memo: [],
    startAt: [],
    closeAt: [],
    customHtml: [],
    middlewareId: [],
    redirectUrl: [],
    graduationYears: [],
    tags: [],
    educationalBackgroundGroups: []
  });
  const [campaignType, setCampaignType] = useState<CampaignType>('inside')
  const [educationalBackgroundGroups, setEducationalBackgroundGroups] = useState<Item<number>[]>([]);
  const [tags, setTags] = useState<Item<number>[]>([]);
  const [graduationYears, setGraduationYears] = useState<Item<Year>[]>([]);
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    // タグ取得 & selectize用に整形
    getTags((res: Tag[]) => {
      const newTags = res.map(tag => {
        return {
          id: tag.id,
          label: tag.name,
          value: tag.id,
          isDisplay: true,
          isSelected: false
        }
      })

      setTags(newTags)
    })
    // 学歴グループ取得
    getEducationalBackgroundGroups((res: EducationalBackgroundGroup[]) => {
      const newEducationalBackgroundGroups = res.map(educationalBackgroundGroup => {
        return {
          id: educationalBackgroundGroup.id,
          label: educationalBackgroundGroup.name,
          value: educationalBackgroundGroup.id,
          isDisplay: true,
          isSelected: false
        }
      })

      setEducationalBackgroundGroups(newEducationalBackgroundGroups)
    })
    // 卒年
    const targetYears = [0, 1, 2, 3, 4, 5].map(n => new Date().getFullYear() + n)
    const yearTemplate = (id: number, year: number, month: number): Item<Year> => {
      return {
        id: id,
        label: `${year}年`,
        value: { year: year, month: month },
        isDisplay: true,
        isSelected: false
      }
    }
    const newYears: Item<Year>[] = targetYears.map((year, i) => {
      return yearTemplate(i + 1, year, 3);
    })

    setGraduationYears(newYears);
  }, [])

  const onChange = (value: CreateData[keyof CreateData], name: keyof CreateData) => {
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData)
  }

  const onSubmit = () => {
    const callback = (res: CreateResponse) => {
      history.push(showCompaniesPathCreator(props.match.params.id))
    }

    const errCallback = (err: CreateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    create(formData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="キャンペーン登録" />
        <Panel className="col-lg-6 col-md-12 col-sm-12">
          <Form noValidate>
            <Form.Group controlId='campaignType'>
              <Form.Label>キャンペーンタイプ</Form.Label>
              <Form.Control
                as="select" custom
                onChange={
                  e => {
                    onChange(e.target.value, 'type')
                    setCampaignType(e.target.value as CampaignType)
                  }
                }
                isInvalid={errors.type?.length > 0}
              >
                <option value='inside'>インサイド</option>
                <option value='outside'>アウトサイド</option>
                <option value='custom'>カスタム</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.type?.map((err, i) => <p key={`error-type-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='campaignName'>
              <Form.Label>キャンペーン名</Form.Label>
              <Form.Control
                type='text'
                name="name"
                onChange={e => onChange(e.target.value, 'name')}
                isInvalid={errors.name?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.map((err, i) => <p key={`error-name-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='campaignMemo'>
              <Form.Label>メモ</Form.Label>
              <Form.Control
                as="textarea"
                name="memo"
                onChange={e => onChange(e.target.value, 'memo')}
                isInvalid={errors.memo?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.memo?.map((err, i) => <p key={`error-memo-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='startAt'>
              <Form.Label>開始日時</Form.Label>
              <DateTime
                className={`col-6 p-0`}
                isInvalid={errors.startAt?.length > 0}
                name='startAt'
                onChange={value => onChange(value, "startAt")}
              />
              <span className="invalid-feedback">
                {errors.startAt?.map((err, i) => <p key={`error-startAt-${i}`}>{err}</p>)}
              </span>
            </Form.Group>

            <Form.Group controlId='closeAt'>
              <Form.Label>終了日時</Form.Label>
              <DateTime
                className={`col-6 p-0`}
                isInvalid={errors.closeAt?.length > 0}
                name='closeAt'
                onChange={value => onChange(value, "closeAt")}
              />
              <span className="invalid-feedback">
                {errors.closeAt?.map((err, i) => <p key={`error-closeAt-${i}`}>{err}</p>)}
              </span>
            </Form.Group>

            <Form.Group controlId='campaignGraduationYears'>
              <Form.Label>卒年</Form.Label>
              <Selectize
                id={'years'}
                items={graduationYears}
                setItems={setGraduationYears}
                onRemove={
                  item => {
                    const value = [...{ ...formData }['graduationYears']].filter(years => !(years == item.value))
                    onChange(value, 'graduationYears');
                  }
                }
                onSelect={
                  item => {
                    const value = [...{ ...formData }['graduationYears'], item.value];
                    onChange(value, 'graduationYears');
                  }
                }
                placeholder={'卒年を選択してください'}
                isInvalid={errors.graduationYears?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.graduationYears?.map((err, i) => <p key={`error-years-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='campaignSchoolGroups'>
              <Form.Label className='col-12 p-0 d-flex justify-content-between'>
                学歴グループ
                <a href={newEducationalBackgroundGroupsPath}>学歴グループを追加する</a>
              </Form.Label>
              <Selectize
                id={'schools'}
                items={educationalBackgroundGroups}
                setItems={setEducationalBackgroundGroups}
                onRemove={
                  item => {
                    const value = [...{ ...formData }['educationalBackgroundGroups']].filter(id => !(id == item.value))
                    onChange(value, 'educationalBackgroundGroups');
                  }
                }
                onSelect={
                  item => {
                    const value = [...{ ...formData }['educationalBackgroundGroups'], item.value];
                    onChange(value, 'educationalBackgroundGroups');
                  }
                }
                placeholder={'学歴グループを入力してください'}
                isInvalid={errors.educationalBackgroundGroups?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.educationalBackgroundGroups?.map((err, i) => <p key={`error-edus-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='campaignTags'>
              <Form.Label className='col-12 p-0 d-flex justify-content-between'>
                タグ
                <a href={newTagsPath}>タグを追加する</a>
              </Form.Label>
              <Selectize
                id={'tags'}
                items={tags}
                setItems={setTags}
                onRemove={
                  item => {
                    const value = [...{ ...formData }['tags']].filter(id => !(id == item.value))
                    onChange(value, 'tags');
                  }
                }
                onSelect={
                  item => {
                    const value = [...{ ...formData }['tags'], item.value];
                    onChange(value, 'tags');
                  }
                }
                placeholder={'タグを入力してください'}
                isInvalid={errors.tags?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tags?.map((err, i) => <p key={`error-tags-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            {(campaignType == 'custom') && (
              <>
                <Form.Group controlId='campaignMiddleware'>
                  <Form.Label>ミドルウェア</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    isInvalid={errors.middlewareId?.length > 0}
                    onChange={e => onChange(e.target.value, 'middlewareId')}
                  >
                    <option></option>
                    {/* TODO : サーバーからミドルウェア一覧を取得し、optionに追加する */}
                    <option value='1'>doda</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.middlewareId?.map((err, i) => <p key={`error-middleware-${i}`}>{err}</p>)}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='customHtml'>
                  <Form.Label>カスタムHTML(利用規約リンクなど)</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="customHtml"
                    placeholder="<a href='https://...'>利用規約</a>"
                    isInvalid={errors.customHtml?.length > 0}
                    onChange={e => onChange(e.target.value, 'customHtml')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.customHtml?.map((err, i) => <p key={`error-cutomHtml-${i}`}>{err}</p>)}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='redirectUrl'>
                  <Form.Label>リダイレクトURL</Form.Label>
                  <Form.Control
                    type='text'
                    name="redirect_url"
                    placeholder="https://..."
                    isInvalid={errors.redirectUrl?.length > 0}
                    onChange={e => onChange(e.target.value, 'redirectUrl')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.redirectUrl?.map((err, i) => <p key={`error-redirectUrl-${i}`}>{err}</p>)}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}

            {(campaignType == 'outside') && (
              <Form.Group controlId='redirectUrl'>
                <Form.Label>リダイレクトURL</Form.Label>
                <Form.Control
                  type='text'
                  name="redirect_url"
                  placeholder="https://..."
                  isInvalid={errors.redirectUrl?.length > 0}
                  onChange={e => onChange(e.target.value, 'redirectUrl')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.redirectUrl?.map((err, i) => <p key={`error-redirectUrl-${i}`}>{err}</p>)}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Button type="button" variant='outline-success' onClick={onSubmit}>登録</Button>
          </Form>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default New;