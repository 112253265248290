import { FC, ReactNode } from 'react';

type PanelTitleProps = {
  title: string;
  children?: ReactNode;
  className?: string;
}

const PanelTitle: FC<PanelTitleProps> = (props) => {
  const { title, children } = props;

  return (
    <div className="d-flex mb-3 align-items-center">
      <h5 className="mr-auto mb-0">{ title }</h5>
      { children }
    </div>
  )
}

PanelTitle.defaultProps = {
  className: ''
}

export default PanelTitle;