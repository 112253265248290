import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import Table from 'components/Table';
import PageTitle from 'components/PageTitle';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import {
  newEducationalBackgroundGroupsPath,
  showEducationalBackgroundGroupsPathCreator,
  editEducationalBackgroundGroupsPathCreator
} from 'routes/educationalBackgroundGroups/path';
import { index, EducationalBackgroundGroup } from 'libs/apiClient/admin/educationalBackgroundGroups';
import Forbidden from 'pages/forbidden';

const Index: FC = () => {
  const history = useHistory();
  const [educationalBackgroundGroups, setEducationalBackgroundGroups] = useState<EducationalBackgroundGroup[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }
    index(setEducationalBackgroundGroups, forbiddenCallback);
  }, []);

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="学歴グループ一覧">
          <Button onClick={() => history.push(newEducationalBackgroundGroupsPath)}>新規追加</Button>
        </PageTitle>

        <Panel>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>学歴グループ名</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {
                educationalBackgroundGroups.map(educationalBackgroundGroup => {
                  return (
                    <tr key={educationalBackgroundGroup.id}>
                      <td className="align-middle" >{educationalBackgroundGroup.id}</td>
                      <td className="align-middle" >{educationalBackgroundGroup.name}</td>
                      <td className="align-middle" >
                        <Button variant='dark' onClick={() => history.push(editEducationalBackgroundGroupsPathCreator(educationalBackgroundGroup.id))}>編集</Button>
                        <Button variant="outline-info" className="ml-3" onClick={() => history.push(showEducationalBackgroundGroupsPathCreator(educationalBackgroundGroup.id))}>詳細</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Index;