export const newCompanyCampaignCreativesPath: string = "/companies/:companyId/campaigns/:campaignId/creatives/new"
export const showCompanyCampaignCreativesPath: string = "/companies/:companyId/campaigns/:campaignId/creatives/:id"
export const editCompanyCampaignCreativesPath: string = "/companies/:companyId/campaigns/:campaignId/creatives/:id/edit"

export const newCompanyCampaignCreativesPathCreator = (companyId: number|string, campaignId: number|string): string => {
  return `/companies/${companyId}/campaigns/${campaignId}/creatives/new`
}
export const showCompanyCampaignCreativesPathCreator = (companyId: number|string, campaignId: number|string, id: number|string): string => {
  return `/companies/${companyId}/campaigns/${campaignId}/creatives/${id}`
}
export const editCompanyCampaignCreativesPathCreator = (companyId: number|string, campaignId: number|string, id: number|string): string => {
  return `/companies/${companyId}/campaigns/${campaignId}/creatives/${id}/edit`
}