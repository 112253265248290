import { useCallback, useMemo } from 'react';
import { post } from 'libs/fetch';

export const useAdmin = () => {
  const createAdmin = useCallback(async () => {
    const response = await post('api/v1/admin/sales_forces/admins', {})
    if (response.ok) {
      return { result: true }
    }

    return { result: false }
  }, []);

  return useMemo(() => ({ createAdmin }),[ createAdmin ])
}
