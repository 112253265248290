import Index from 'pages/admins/index';
import New from 'pages/admins/new';
import Edit from 'pages/admins/edit';

import {
  indexAdminsPath,
  newAdminsPath,
  editAdminsPath,
} from './path';

export const adminsRoutes = [
  {
    name: "管理者",
    path: indexAdminsPath,
    component: Index,
    auth: true,
    visible: true
  },
  {
    name: "管理者登録",
    path: newAdminsPath,
    component: New,
    auth: true
  },
  {
    name: "管理者編集",
    path: editAdminsPath,
    component: Edit,
    auth: true
  },
]
