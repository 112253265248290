import { FC, useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import {
  indexPath,
  createPath,
  updatePath,
  Entry,
  RequestData,
  Errors,
  Response
} from 'libs/apiClient/admin/entries';
import { get, create, show, update, destroy } from 'libs/apiClient';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import Toast from 'components/Toast';
import { Area, indexPath as AreasPath } from 'libs/apiClient/admin/areas';
import { useEntries } from 'hooks/api/admin/user/useEntries'

type Props = {
  userId: number | string | null;
}

const Entries: FC<Props> = (props)  => {
  const { userId } = props;
  const [errors, setErrors] = useState<Errors>({});
  const {entries} = useEntries(String(userId))
  const [areas, setAreas] = useState<Area[]>([]);
  const [isDisplayModal, setisDisplayModal] = useState(false);
  const [entryId, setEntryId] = useState<number|string|null>(null);
  const [entryStatus, setEntryStatus] = useState<string|null>(null);
  const [entryRoute, setEntryRoute] = useState<string|null>(null);
  const [isDisplayToast, setIsDisplayToast] = useState(false);
  const [message, setMessage] = useState<string|null>(null);

  useEffect(() => {
    // エリアマスターの取得
    get(AreasPath, 'areas', setAreas);
  }, [userId]);

  const onUpdate = (entry: Entry) => {
    setisDisplayModal(true);
    setEntryId(entry.id);
    setEntryStatus(entry.status);
  }

  const onClose = () => {
    setisDisplayModal(false);
    setEntryId(null);
    setEntryStatus(null);
    setEntryRoute(null);
  }

  const onSubmit = (entryId: number|string|null, entryStatus: string|null, entryRoute: string|null): void => {
    const callback = (_res: Response) => {
      setErrors({});
      setMessage(entryStatus == 'participation' ? 'キャンセルしました' : 'エントリーしました');
      onClose();
      setIsDisplayToast(true);
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }

    const requestData = {
      'entry' : {
        'status': entryStatus == 'participation' ? 'cancel' : 'participation',
        'entry_route': entryRoute
      }
    }
    entryId && update(updatePath(entryId), requestData, 'entry', callback, errCallback);
  }

  return (
    <>
      <Panel className='pb-4'>
      <PanelTitle title={ `登録キャンペーン情報(ユーザーID: ${userId})` } />
        <Table className='mb-2'>
          <thead>
            <tr>
              <th>ID</th>
              <th>キャンペーン名</th>
              <th>エリア</th>
              <th>会場</th>
              <th>日時</th>
              <th>ステータス</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            { entries && (
              entries.map(entry => {
                return (
                  <tr key={entry.id}>
                    <td className={ `align-middle` } >{entry.id}</td>
                    <td className={ `align-middle` } >{entry.campaignName}</td>
                    <td className={ `align-middle` } >
                      { areas.map(area => {
                          if (area.id == entry.schedule.areaId){
                            return area.name
                          }
                        })
                      }
                    </td>                    

                    <td className={ `align-middle` } >{entry.schedule.address1} {entry.schedule.address2}</td>
                    <td className={ `align-middle` } >{entry.schedule.startAt} - {entry.schedule.endAt}</td>
                    <td className={ `align-middle` } >{entry.status == 'participation' ? 'エントリー' : 'キャンセル' }</td>
                    <td className={ `align-middle`} >
                      <Button
                        variant={entry.status == 'participation' ? 'outline-danger' : 'outline-success' }
                        onClick={() => onUpdate(entry)}
                      >
                        {entry.status == 'participation' ? 'キャンセル' : 'エントリー' }
                      </Button>
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </Table>
      </Panel>

      <Modal show={isDisplayModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{entryStatus == 'participation' ? 'キャンセル' : 'エントリー' }しますか？</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Form.Group controlId='jobHuntingStatus'>
            <Form.Label>登録経路を選択してください</Form.Label>
            <>
              <Form.Control
                as="select"
                custom
                onChange={ e => {setEntryRoute(e.target.value)} }
                isInvalid={ errors.entryRoute && errors.entryRoute?.length > 0 }
              >
                <option></option>
                <option value='架電'>架電</option>
                <option value='クライアント問い合わせ'>クライアント問い合わせ</option>
                <option value='ユーザー問い合わせ'>ユーザー問い合わせ</option>
              </Form.Control>
            </>
            <Form.Control.Feedback type="invalid">
              { errors.entryRoute?.map((err, i) => <p key={ `error-entryRoute-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            いいえ
          </Button>
          <Button variant="primary" onClick={() => onSubmit(entryId, entryStatus, entryRoute)}>
            はい
          </Button>
        </Modal.Footer>
      </Modal>

      { isDisplayToast && (
        <Toast
          message={message}
          isDisplayToast={isDisplayToast}
          setIsDisplayToast={setIsDisplayToast}
        />
        )
      }
    </>
  )
}

export default Entries;
