import { FC, useState } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Button, Form, Spinner } from 'react-bootstrap';
import { create, CreateData, CreateErrors, CreateResponse } from 'libs/apiClient/admin/companies';
import { useHistory } from 'react-router';
import { indexCompaniesPath } from 'routes/companies/path';
import Forbidden from 'pages/forbidden';

const New: FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [postData, setPostData] = useState<CreateData>({ name: '' });
  const [errors, setErrors] = useState<CreateErrors>({ name: [] });
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  const onChange = (value: string, key: 'name'): void => {
    const data = { ...postData }
    data[key] = value;

    setPostData(data);
  }

  const onSubmit = async () => {
    setLoading(true)

    const callback = (_params: CreateResponse) => {
      history.push(indexCompaniesPath)
    }

    const errCallback = (err: CreateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await create(postData, callback, errCallback, forbiddenCallback);

    setLoading(false)
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="企業登録" />
        <Panel className="col-6">
          <Form noValidate>
            <Form.Group controlId='formBasicName'>
              <Form.Label>企業名</Form.Label>
              <Form.Control
                type='text'
                name="name"
                placeholder="〇〇株式会社"
                onChange={e => onChange(e.currentTarget.value, "name")}
                isInvalid={errors['name'].length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name.map(err => <p>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="button" onClick={onSubmit} variant='outline-success'>
              {
                loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
              }
              登録
            </Button>
          </Form>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default New;