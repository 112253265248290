import { FC, Dispatch } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  CreateData,
  CreateErrors,
  UpdateData,
  UpdateErrors
} from 'libs/apiClient/admin/tags';

type FormProps = {
  formData: CreateData | UpdateData;
  setFormData: Dispatch<React.SetStateAction<CreateData | UpdateData>>;
  errors: CreateErrors | UpdateErrors;
  onSubmit: () => void;
}

const TagForm: FC<FormProps>= (props) => {
  const {
    formData,
    setFormData,
    errors,
    onSubmit
  } = props;

  const onChange = (value: string, key: 'name'): void => {
    const data = { ...formData }
    data[key] = value;

    setFormData(data);
  }

  return (
    <Form noValidate>
      <Form.Group controlId='formBasicName'>
        <Form.Label>タグ名</Form.Label>
        <Form.Control
          type='text'
          name="name"
          onChange={ e => onChange(e.currentTarget.value, "name") }
          defaultValue={ formData.name }
          isInvalid={ errors.name?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.name?.map((err, i) => <p key={ `error-name-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="button" onClick={ onSubmit } variant='outline-success'>
        登録
      </Button>
    </Form>
  )
}

export default TagForm;
