export const createPath = '/api/v1/admin/remind_mail';
export const showPath = (campaignId: string | number): string => `api/v1/admin/remind_mail?campaign_id=${campaignId}`;
export const updatePath = `api/v1/admin/remind_mail`;

export type RemindMail = {
  id: number;
  title: string;
  body: string;
  htmlBody?: string;
  campaignId: string | number;
  sendTimings: number[];
}

/* patch request */
export type RequestData = {
  title: string;
  body: string;
  htmlBody?: string;
  campaignId: string | number;
  sendTimings: number[];
}

export type Response = {
  id: number;
}

export type Errors = {
  title?: string[];
  body?: string[];
  htmlBody?: string[];
  campaignId?: string[];
  sendTimings?: string[];
}
