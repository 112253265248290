import Index from 'pages/educationalBackgroundGroups/index';
import New from 'pages/educationalBackgroundGroups/new';
import Show from 'pages/educationalBackgroundGroups/show';
import Edit from 'pages/educationalBackgroundGroups/edit';


import {
  indexEducationalBackgroundGroupsPath,
  newEducationalBackgroundGroupsPath,
  showEducationalBackgroundGroupsPath,
  editEducationalBackgroundGroupsPath
} from './path';

export const educationalBackgroundGroupsRoutes = [
  {
    name: "学歴グループ",
    path: indexEducationalBackgroundGroupsPath,
    component: Index,
    auth: true,
    visible: true
  },
  {
    name: "学歴グループ作成",
    path: newEducationalBackgroundGroupsPath,
    component: New,
    auth: true
  },
  {
    name: "学歴グループ詳細",
    path: showEducationalBackgroundGroupsPath,
    component: Show,
    auth: true
  },
  {
    name: "学歴グループ編集",
    path: editEducationalBackgroundGroupsPath,
    component: Edit,
    auth: true
  }
]

