import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import Table from 'components/Table';
import PageTitle from 'components/PageTitle';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import {
  newTagsPath,
  showTagsPathCreator,
  editTagsPathCreator
} from 'routes/tags/path';
import { index, Tag } from 'libs/apiClient/admin/tags';
import Forbidden from 'pages/forbidden';

const Index: FC = () => {
  const history = useHistory();
  const [tags, setTags] = useState<Tag[]>([]);
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }
    index(setTags, forbiddenCallback);
  }, []);

  const Page = () => {

    return (
      <Layout>
        <PageTitle title="タグ一覧">
          <Button onClick={() => history.push(newTagsPath)}>新規追加</Button>
        </PageTitle>

        <Panel>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>タグ名</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {
                tags.map(tag => {
                  return (
                    <tr key={tag.id}>
                      <td className="align-middle" >{tag.id}</td>
                      <td className="align-middle" >{tag.name}</td>
                      <td className="align-middle" >
                        <Button variant='dark' onClick={() => history.push(editTagsPathCreator(tag.id))}>編集</Button>
                        <Button variant="outline-info" className="ml-3" onClick={() => history.push(showTagsPathCreator(tag.id))}>詳細</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Index;