import { FC, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import {
  createPath,
  RequestData,
  Errors,
  Response
} from 'libs/apiClient/admin/users';
import { create } from 'libs/apiClient';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import { useFetchPrefectures } from 'hooks/api/prefectures/useFetchPrefectures';
import { ErrorToast } from 'components/ErrorToast';

type FormProps = {
  onClose: () => void;
}

const UserCreateForm: FC<FormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState<RequestData>({
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    email: '',
    phoneNumber: '',
    graduateYear: 2023,
    graduateMonth: 3,
    prefectureId: null,
    gender: 'man',
    specialityClassification: 'arts',
    jobHuntingStatus: 'active',
    isEmailAvailable: true,
    isPhoneAvailable: false
  })
  const [errors, setErrors] = useState<Errors>({
    firstName: [],
    lastName: [],
    firstNameKana: [],
    lastNameKana: [],
    email: [],
    phoneNumber: [],
    graduateYear: [],
    graduateMonth: [],
    prefectureId: [],
    gender: [],
    specialityClassification: [],
  });
  const targetYears = [0, 1, 2, 3, 4, 5].map(n => new Date().getFullYear() + n)
  const graduationYearOptions = targetYears.map(year => ({
    id: year,
    label: `${year}年`,
    value: year,
    isDisplay: true,
    isSelected: false
  }))
  const { prefectures } = useFetchPrefectures()
  const [isDisplayErrorToast, setIsDisplayErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string|null>(null);

  const onChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  const handleSubmit = () => {
    const callback = (_res: Response) => {
      onClose()
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }

    const serverErrCallback = (errMessage: string) => {
      setErrorMessage(errMessage)
      setIsDisplayErrorToast(true);
    }

    create(createPath, { user: { ...formData } }, 'user', callback, errCallback, serverErrCallback);
  }

  return (
    <>
      <Panel className='pb-4'>
        <PanelTitle title={'ユーザー新規作成（電話停止登録）'} />
        <Form noValidate className='col-6'>
          <Form.Group controlId='graduationYear'>
            <Form.Label>卒年</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={e => { onChange(Number(e.target.value), 'graduateYear') }}
              isInvalid={errors.graduateYear && errors.graduateYear?.length > 0}
            >
              <option></option>
              {graduationYearOptions.map((graduationYear) =>
                <option
                  key={`graduationYear-${graduationYear.id}`}
                  value={graduationYear.value}
                >
                  {graduationYear.label}
                </option>
              )}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.graduateYear?.map((err, i) => <p key={`error-graduateYear-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Form.Group as={Col} md="6" controlId='lastName'>
              <Form.Label>姓</Form.Label>
              <Form.Control
                type='text'
                name="lastName"
                value={formData.lastName}
                onChange={e => onChange(e.target.value, 'lastName')}
                placeholder={'姓を入力してください'}
                isInvalid={errors.lastName && errors.lastName?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName?.map((err, i) => <p key={`error-lastName-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId='firstName'>
              <Form.Label>名</Form.Label>
              <Form.Control
                type='text'
                name="firstName"
                value={formData.firstName}
                onChange={e => onChange(e.target.value, 'firstName')}
                placeholder={'名を入力してください'}
                isInvalid={errors.firstName && errors.firstName?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName?.map((err, i) => <p key={`error-firstName-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="6" controlId='lastNameKana'>
              <Form.Label>セイ</Form.Label>
              <Form.Control
                type='text'
                name="lastNameKana"
                value={formData.lastNameKana}
                onChange={e => onChange(e.target.value, 'lastNameKana')}
                placeholder={'セイを入力してください'}
                isInvalid={errors.lastNameKana && errors.lastNameKana?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastNameKana?.map((err, i) => <p key={`error-lastNameKana-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId='firstNameKana'>
              <Form.Label>メイ</Form.Label>
              <Form.Control
                type='text'
                name="firstNameKana"
                value={formData.firstNameKana}
                onChange={e => onChange(e.target.value, 'firstNameKana')}
                placeholder={'メイを入力してください'}
                isInvalid={errors.firstNameKana && errors.firstNameKana?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstNameKana?.map((err, i) => <p key={`error-firstNameKana-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group controlId='phoneNumber'>
            <Form.Label>電話番号</Form.Label>
            <Form.Control
              type='text'
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={e => onChange(e.target.value, 'phoneNumber')}
              placeholder={'09012345678'}
              isInvalid={errors.phoneNumber && errors.phoneNumber?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber?.map((err, i) => <p key={`error-phoneNumber-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Label>メールアドレス</Form.Label>
            <Form.Control
              type='text'
              name="email"
              value={formData.email}
              onChange={e => onChange(e.target.value, 'email')}
              placeholder={'shutatsu-times@gmail.com'}
              isInvalid={errors.email && errors.email?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.map((err, i) => <p key={`error-email-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='prefectureId'>
            <Form.Label>お住いの都道府県</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={e => { onChange(e.target.value, 'prefectureId') }}
              isInvalid={errors.prefectureId && errors.prefectureId?.length > 0}
            >
              <option></option>
              {prefectures.map((prefecture) =>
                <option
                  key={`prefecture-${prefecture.id}`}
                  value={prefecture.id}
                >
                  {prefecture.name}
                </option>
              )}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.prefectureId?.map((err, i) => <p key={`error-prefectureId-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Button type="button" className='mr-2' variant="outline-secondary" onClick={onClose}>キャンセル</Button>
            <Button type="button" variant='outline-success' onClick={handleSubmit}>保存</Button>
          </Form.Group>
        </Form>
      </Panel>

      { isDisplayErrorToast && (
        <ErrorToast
          message={errorMessage}
          isDisplayToast={isDisplayErrorToast}
          setIsDisplayToast={setIsDisplayErrorToast}
        />
      )}
    </>
  )
}

export default UserCreateForm;
