import Index from 'pages/companies/index';
import New from 'pages/companies/new';
import Show from 'pages/companies/show';
import Edit from 'pages/companies/edit';
import { companyCampaignsRoutes } from './campaigns';

import {
  indexCompaniesPath,
  newCompaniesPath,
  editCompaniesPath,
  showCompaniesPath
} from './path';

export const companiesRoutes = [
  {
    name: "企業",
    path: indexCompaniesPath,
    component: Index,
    auth: true,
    visible: true
  },
  {
    name: "企業作成",
    path: newCompaniesPath,
    component: New,
    auth: true
  },
  {
    name: "企業詳細",
    path: showCompaniesPath,
    component: Show,
    auth: true
  },
  {
    name: '企業編集',
    path: editCompaniesPath,
    component: Edit,
    auth: true,
  },
  ...companyCampaignsRoutes
]

