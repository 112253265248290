import { get } from 'libs/fetch'
import { useCallback, useState } from 'react';

type UniversityDepartment = {
  id: number;
  name: string;
  universityFacultyId: string;
}

export const useFetchUniversityDepartments = () => {
  const [universityDepartments, setUniversities] = useState<UniversityDepartment[]>([])

  const fetchUniversityDepartments = useCallback(async (universityFacultyId: string | number) => {
    const response = await get(`/api/v1/university_departments?university_faculty_id=${universityFacultyId}`)

    if (response.ok) {
      const body: { universityDepartments: UniversityDepartment[] } = await response.json();
      setUniversities(body.universityDepartments);
      return
    }

    const error: Error = new Error('エラーになりました');
    throw error;
  }, []);

  return { universityDepartments, fetchUniversityDepartments }
};
