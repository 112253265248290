import { FC, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import { CsvImportForm } from './CsvImportForm';
import { CreateForm } from './CreateForm';
import { UpdateForm } from './UpdateForm';
import { useFetchSchedules } from 'hooks/api/admin/schedules/useFetchSchedules';
import { Schedule } from 'hooks/api/admin/schedules/type';

type Props = {
  campaignId: string;
}

export const Schedules: FC<Props> = ({ campaignId }) => {
  const { data, getter: getSchedules } = useFetchSchedules(campaignId, true)
  const [mode, setMode] = useState<'create' | 'update' | 'csv'>('create')
  const [isDisplayForm, setIsDisplayForm] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<Schedule>();

  const onCsvImport = () => {
    setMode('csv')
    setSchedule(undefined)
    setIsDisplayForm(true)
  }

  const onCreate = () => {
    setMode('create')
    setIsDisplayForm(true);
  }

  const onClose = () => {
    setMode('create')
    setSchedule(undefined)
    setIsDisplayForm(false);
  }

  const onUpdate = (schedule: Schedule) => {
    setMode('update')
    setSchedule(schedule)
    setIsDisplayForm(true);
  }

  const onReload = () => {
    getSchedules()
  }

  return (
    <>
      <PanelTitle title='スケジュール'>
        <Button variant="secondary" onClick={ _e => onReload() } className='mr-2'>リロード</Button>
        <Button variant="primary" onClick={ _e => onCsvImport() } className='mr-2'>csvインポート</Button>
        <Button variant="primary" onClick={ _e => onCreate() }>新規作成</Button>
      </PanelTitle>
      <Table className='mb-2'>
        <thead>
          <tr>
            <th>ID</th>
            <th>開始日時</th>
            <th>終了日時</th>
            <th>参加上限</th>
            <th>公開開始</th>
            <th>公開終了</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          { data?.schedules && data.schedules.map(schedule => (
              <tr key={schedule.id}>
                <td className={ `align-middle` } >{schedule.id}</td>
                <td className={ `align-middle` } >{schedule.startAt}</td>
                <td className={ `align-middle` } >{schedule.endAt}</td>
                <td className={ `align-middle` } >{schedule.participantLimit}</td>
                <td className={ `align-middle` } >{schedule.publicStartAt}</td>
                <td className={ `align-middle` } >{schedule.publicEndAt}</td>
                <td className={ `align-middle`} >
                  <Button
                    variant='dark'
                    onClick={() => onUpdate(schedule)}
                  >
                    編集
                  </Button>
                </td>
              </tr>
          ))}
        </tbody>
      </Table>

      { (isDisplayForm && (mode == 'create')) && (
        <CreateForm
          campaignId={campaignId}
          onClose={onClose}
          reload={onReload}
        />
      )}

      { (isDisplayForm && schedule && (mode == 'update')) && (
        <UpdateForm
          schedule={schedule}
          campaignId={campaignId}
          onClose={onClose}
          reload={onReload}
        />
      )}

      { (isDisplayForm && (mode == 'csv')) && (
        <CsvImportForm
          title='csvインポート'
          campaignId={ campaignId }
        />
      )}
    </>
  )
}
