import { Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'
import UnauthRoute from './UnauthRoute'
import ResetPasswordRoute from './ResetPasswordRoute'
import NotFoundRoute from './NotFoundRoute'
import Top from 'pages/Top'
import Login from 'pages/Login'
import ResetPassword from 'pages/ResetPassword'
import NotFound from 'pages/NotFound'
import { routes } from './route'

const RouteList = () => {
  return (
    <Switch>
      <UnauthRoute exact path='/login' component={Login} />
      <PrivateRoute exact path='/' component={Top} />
      {
        routes.map((route, key) => {
          return (
            route.auth
              ? <PrivateRoute key={`private-route-${key}`} exact path={route.path} component={route.component} />
              : <UnauthRoute key={`unauth-route-${key}`} exact path={route.path} component={route.component} />
          )
        })
      }
      <ResetPasswordRoute path='/reset_password' component={ResetPassword} />
      <NotFoundRoute component={NotFound} />
    </Switch>
  )
}

export default RouteList
