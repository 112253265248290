import { FC, useState, useEffect } from 'react'
import { Schedule } from 'hooks/api/admin/schedules/type'
import { useUpdateSchedules, UpdateRequestBody, UpdateErrorResponse } from 'hooks/api/admin/schedules/useUpdateSchedule'
import { ScheduleForm } from './ScheduleForm'
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';

type UpdateScheduleFormProps = {
  campaignId: string
  schedule: Schedule
  onClose: () => void
  reload: () => void
}

export type UpdateFormData = UpdateRequestBody['schedule']
export type UpdateFormError = UpdateErrorResponse['errors']

export const UpdateForm: FC<UpdateScheduleFormProps> = ({
  campaignId,
  schedule,
  onClose,
  reload
}) => {
  const defaultValues: (schedule: Schedule) => UpdateFormData = () => ({
    type: schedule.isOnline ? 'OnlineSchedule' : 'OfflineSchedule',
    onlineText: schedule.onlineText,
    prefectureId: schedule.prefecture?.id || '',
    postcode: schedule.postCode || '',
    city: schedule.city || '',
    building: schedule.building || '',
    station: schedule.station || '',
    isSchedule: schedule.isSchedule,
    noScheduleText: schedule.noScheduleText,
    startAt: schedule.startAt || '',
    endAt: schedule.endAt || '',
    isPublished: schedule.isPublished,
    publicStartAt: schedule.publicStartAt,
    publicEndAt: schedule.publicEndAt,
    participantLimit: schedule.participantLimit,
    remarks: schedule.remarks,
    areaId: schedule.area.id,
    targetPrefectureIds: schedule.targetPrefectures?.map(prefecture => prefecture.id) || [],
    targetSpecialityClassifications: schedule.targetSpecialityClassifications || []
  });

  const [formData, setFormData] = useState<UpdateFormData>(defaultValues(schedule))
  const [errors, setErrors] = useState<UpdateFormError>({})
  const { update } = useUpdateSchedules(campaignId, schedule.id)

  const onChangeValue = (values: UpdateFormData) => {
    setFormData(values)
  }

  const onSubmit = async () => {
    const response = await update({ schedule: formData });
    const responseBody = await response.json();

    switch(response.status) {
      case 200:
        onClose()
        reload()
        return
      case 400:
        const errors: UpdateFormError = responseBody.errors;
        setErrors(errors);
        return
    }
  }

  useEffect(() => {
    setFormData(defaultValues(schedule))
  }, [schedule])

  return (
    <Panel>
      <PanelTitle title={ `スケジュール編集(ID: ${schedule.id})` }/>

      <ScheduleForm
        key={schedule.id}
        errors={errors}
        formData={formData}
        setFormData={onChangeValue}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Panel>
  )
}