import { get, post } from 'libs/fetch';
import { Tag } from 'libs/apiClient/admin/tags';
import { EntryForm } from 'libs/apiClient/admin/entryForms';
import { ThanksMail } from 'libs/apiClient/admin/thanksMails';
import { RemindMail } from 'libs/apiClient/admin/remindMail';
import { EntrySpreadsheet } from 'libs/apiClient/admin/entrySpreadsheets';
import { Creative } from 'libs/apiClient/admin/creatives';

/* get request */
export type Campaign = {
  id: number;
  type: CampaignType;
  campaignable: InsideCampaign | OutsideCampaign | CustomCampaign;
  graduationYears: Year[];
  tags: Tag[]
  educationalBackgroundGroups: { id: number, name: string }[];
  entryForm?: EntryForm;
  thanksMail?: ThanksMail;
  remindMail?: RemindMail;
  entrySpreadsheet?: EntrySpreadsheet;
  creatives: Creative[];
  companyId: number | string;
}

export type InsideCampaign = {
  name: string;
  memo: string;
  startAt: string;
  closeAt: string;
}

export type OutsideCampaign = {
  name: string;
  memo: string;
  redirectUrl: string;
  startAt: string;
  closeAt: string;
}

export type CustomCampaign = {
  name: string;
  memo: string;
  customHtml: string;
  startAt: string;
  closeAt: string;
  middleware: {
    id: number;
    label: string;
    name: string;
  };
  redirectUrl: string;
};

export type Year = {
  id: number;
  year: number;
  month: number;
}

export type CampaignType = 'inside' | 'outside' | 'custom';

export const index = async (
  companyId: string | null,
  callback: (responseData: Campaign[]) => void,
  forbiddenCallback?: () => void,
) => {
  const queryParams = companyId ? new URLSearchParams({ company_id: companyId }) : '';
  const res = await get(`api/v1/admin/campaigns?${queryParams}`)

  if (res.status === 200) {
    const body = await res.json();
    callback(body.campaigns);
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

export const show = async (
  campaignId: string | number,
  callback: (responseData: Campaign) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get(`api/v1/admin/campaigns/${campaignId}`)
  const body = await res.json();

  if (res.status === 200) {
    callback(body.campaign);
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

export type CreateData = {
  companyId: number | string;
  type: 'inside' | 'outside' | 'custom';
  name: string;
  memo: string;
  startAt: string;
  closeAt: string;
  customHtml?: string;
  middlewareId?: number;
  redirectUrl?: string;
  graduationYears: { year: number, month: number }[];
  tags: number[];
  educationalBackgroundGroups: number[];
}

export type CreateErrors = {
  companyId: string[];
  type: string[];
  name: string[];
  memo: string[];
  startAt: string[];
  closeAt: string[];
  customHtml: string[];
  middlewareId: string[];
  redirectUrl: string[];
  graduationYears: string[];
  tags: string[];
  educationalBackgroundGroups: string[];
}

export type CreateResponse = {
  id: number;
}

export const create = async (
  data: CreateData,
  callback: (responseData: CreateResponse) => void,
  errCallback: (errors: CreateErrors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await post('/api/v1/admin/campaigns', { campaign: data })
  const body = await res.json();

  if (res.status == 200) {
    const responseData = body.campaign;
    callback(responseData)
  } else if (res.status == 400) {
    const errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }

  // 500エラー時の対応
}


export const showPath = (id: number | string) => {
  return `api/v1/admin/campaigns/${id}`;
}

export const updatePath = (id: number | string) => {
  return `api/v1/admin/campaigns/${id}`;
}

export type RequestData = {
  companyId: number | string;
  type: CampaignType;
  name: string;
  memo: string;
  startAt: string;
  closeAt: string;
  customHtml?: string;
  middlewareId?: number;
  redirectUrl?: string;
  graduationYears: { year: number, month: number }[];
  tags: number[];
  educationalBackgroundGroups: number[];
}

export type Errors = {
  companyId?: string[];
  type?: string[];
  name?: string[];
  memo?: string[];
  startAt?: string[];
  closeAt?: string[];
  customHtml?: string[];
  middlewareId?: string[];
  redirectUrl?: string[];
  graduationYears?: string[];
  tags?: string[];
  educationalBackgroundGroups?: string[];
}

export type Response = {
  id: number | string
}

