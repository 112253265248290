import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import Table from 'components/Table';
import PageTitle from 'components/PageTitle';
import { Button } from 'react-bootstrap';
import { useAdmin } from 'hooks/api/admin/salesForces/useAdmin';
import { useEntry } from 'hooks/api/admin/salesForces/useEntry';
import { useEvent } from 'hooks/api/admin/salesForces/useEvent';
import { useSchedule } from 'hooks/api/admin/salesForces/useSchedule';

type isComplete = {
  admin: Boolean,
  entry: Boolean,
  event: Boolean,
  schedule: Boolean  
}

const Index: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createAdmin } = useAdmin();
  const { createEntry } = useEntry();
  const { createEvent } = useEvent();
  const { createSchedule } = useSchedule();
  const isCompleteTemplate = {
    admin: false,
    entry: false,
    event: false,
    schedule: false
  }
  const [isComplete, setIsComplete] = useState<isComplete>(isCompleteTemplate);

  const onClick = async (key: keyof isComplete) => {
    setIsLoading(true);
    switch (key) {
      case 'admin':
        await createAdmin();
        break;
      case 'entry':
        await createEntry();
        break;
      case 'event':
        await createEvent();
        break;
      case 'schedule':
        await createSchedule();
    }
    setIsLoading(false);
    const data = { ...isComplete, [key]: true }
    setIsComplete(data);
  }

  return (
    <Layout>
    <PageTitle title="Salesforceデータ連携"/>
    <p>
      送信ボタンを押すと、バックグラウンドでSalesforceにデータが送信されます。
      <br/>
      Salesforceにデータが送信されるとslackに通知が届きます。
    </p>

    <Panel>
      <Table>
        <thead>
          <tr>
            <th>データ名</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-middle" >インサイドセールス荷電者</td>
            <td className="align-middle" >
              {isComplete.admin ? (
                <span>送信しました</span>
              ) : (
                <>
                  <Button
                    disabled={isLoading}
                    onClick={() => onClick("admin")}
                  >
                    送信
                  </Button>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="align-middle" >エントリー</td>
            <td className="align-middle" >
              {isComplete.entry ? (
                <span>送信しました</span>
              ) : (
                <>
                  <Button
                    disabled={isLoading}
                    onClick={() => onClick("entry")}
                  >
                    送信
                  </Button>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="align-middle" >イベント</td>
            <td className="align-middle" >
              {isComplete.event ? (
                <span>送信しました</span>
              ) : (
                <>
                  <Button
                    disabled={isLoading}
                    onClick={() => onClick("event")}
                  >
                    送信
                  </Button>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="align-middle" >スケジュール</td>
            <td className="align-middle" >
              {isComplete.schedule ? (
                <span>送信しました</span>
              ) : (
                <>
                  <Button
                    disabled={isLoading}
                    onClick={() => onClick("schedule")}
                  >
                    送信
                  </Button>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Panel>
  </Layout>
  )
}

export default Index;