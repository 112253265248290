import { Container, Row } from 'react-bootstrap';

const NotFound: React.FC = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <h1>ページが見つかりませんでした</h1>
      </Row>
    </Container>
  )
}

export default NotFound;
