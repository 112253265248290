import { useCallback, useMemo } from 'react';
import { post, get } from 'libs/fetch';
import { UserCsvDownloadLink } from 'types/api/users/csv';

export const useUserCsv = () => {
  const createCsv = useCallback(async () => {
    const response = await post('api/v1/admin/user_csv', {})
    if (response.ok) {
      return { result: true }
    }

    return { result: false }
  }, []);

  const fetchUserCsvDownloadLink = useCallback(async (token: string) => {
    const search = new URLSearchParams();
    search.append('token', token);
    const response = await get(`api/v1/admin/user_csv?${search.toString()}`);

    if (response.ok) {
      const body: UserCsvDownloadLink = await response.json();
      return { result: true, link: body.downloadLink, status: 200 }
    }

    if (response.status === 400) {
      return { result: false, status: 400 }
    }

    return { result: false, status: 500 }
  }, []);

  return useMemo(
    () => ({
      createCsv,
      fetchUserCsvDownloadLink
    }),
    [
      createCsv,
      fetchUserCsvDownloadLink
    ]
  )
}