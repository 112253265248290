require('dotenv').config();

const host = process.env.REACT_APP_API_HOST

export const get = async (path: string) => {
  const response = await fetch(`${host}/${path}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
  })

  return response;
}

export const post = async <RequestBody = object>(path: string, body: RequestBody) => {
  const response = await fetch(`${host}/${path}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: JSON.stringify(body)
  })

  return response;
}

export const postFormData = async (path: string, body: FormData) => {
  const response = await fetch(`${host}/${path}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    body
  })

  return response;
}

export const patch = async <RequestBody = object>(path: string, body: RequestBody) => {
  const response = await fetch(`${host}/${path}`, {
    method: 'PATCH',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: JSON.stringify(body)
  })

  return response;
}

export const patchFormData = async (path: string, body: FormData) => {
  const response = await fetch(`${host}/${path}`, {
    method: 'PATCH',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    body
  })

  return response;
}



export const destroy = async (path: string) => {
  const response = await fetch(`${host}/${path}`,{
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })

  return response;
}
