import { FC } from 'react';
import Layout from 'layouts/Layout';

const Forbidden: FC = () => {
  return (
    <Layout>
      <div>Forbidden</div>
    </Layout>
  )
}

export default Forbidden;