import React, { useState } from 'react';
import { post } from 'libs/fetch';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

type FormDataKey = 'email' | 'password';

const Login: React.FC = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({ session: { email: '', password: '' } });
  const [error, setError] = useState({ message: '' })

  const onSubmit = async () => {
    const response = await post('api/v1/admin/sign_in', formData)
    if (response.ok) {
      history.push('/');
    }

    setError({ message: 'メールアドレスかパスワードが不正な値です。' })
  }

  const onChange = (value: string, key: FormDataKey): void => {
    const data = { ...formData }
    data.session[key] = value;

    setFormData(data);
  }

  return(
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={12} md={6}>
          <h1 className='mt-5'>#Login</h1>
          <Form className='mt-5 p-3 border border-secondary rounded'>
            <Form.Group controlId='formBasicEmail'>
              <Form.Label>email</Form.Label>
              <Form.Control type='email' placeholder='enter email' onChange={ e => onChange(e.currentTarget.value, 'email') } />
            </Form.Group>

            <Form.Group controlId='formBasicPassword'>
              <Form.Label>password</Form.Label>
              <Form.Control type='password' placeholder='enter password' onChange={ e => onChange(e.currentTarget.value, 'password') } />
            </Form.Group>

            { error.message && <p>{error.message}</p> }
            <Button variant='outline-primary' onClick={onSubmit}>Login</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Login;
