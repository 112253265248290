import { FC, useState, } from 'react';
import { useHistory } from 'react-router';
import { indexAdminsPath } from 'routes/admins/path';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import AdminForm from '../components/AdminForm';
import {
  create, CreateResponse,
  RequestData, Errors, RequestTemplate, ErrorsTemplate,
} from 'libs/apiClient/admin/administrators';
import Forbidden from 'pages/forbidden';

const New: FC = () => {
  const history = useHistory()
  const [createData, setCreateData] = useState<RequestData>({ ...RequestTemplate })
  const [errors, setErrors] = useState<Errors>({ ...ErrorsTemplate })
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  const onSubmit = async () => {
    const callback = (res: CreateResponse) => {
      history.push(indexAdminsPath)
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await create(createData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="管理者登録" />
        <Panel>
          <AdminForm
            formData={createData}
            setFormData={setCreateData}
            errors={errors}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default New;