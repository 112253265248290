import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import { Button } from 'react-bootstrap';
import { RouteComponentProps, useHistory } from 'react-router';
import { show, Area } from 'libs/apiClient/admin/areas';
import { editAreasPathCreator } from 'routes/areas/path';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Show: FC<urlProps> = (props: urlProps) => {
  const history = useHistory()
  const [area, setArea] = useState<Area>()
  const areaId = props.match.params.id

  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }

    show(areaId, (responseData: Area) => {
      setArea(responseData);
    }, forbiddenCallback)
  }, []);

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="エリア詳細" />
        <Panel className='pb-2'>
          <PanelTitle title='基本情報' />
          <p>id: {area?.id}</p>
          <p>エリア名: {area?.name}</p>
        </Panel>
        <Panel>
          <PanelTitle title='都道府県一覧'>
            <Button variant="primary" onClick={() => history.push(editAreasPathCreator(areaId))}>編集</Button>
          </PanelTitle>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>都道府県名</th>
              </tr>
            </thead>
            <tbody>
              {
                area?.prefectures?.map(prefecture => {
                  return (
                    <tr key={prefecture.id}>
                      <td className="align-middle">{prefecture.id}</td>
                      <td className="align-middle">{prefecture.name}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Show;