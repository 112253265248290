import New from 'pages/companies/campaigns/new';
import Show from 'pages/companies/campaigns/show';
import Edit from 'pages/companies/campaigns/edit';

import { companyCampaignCreativesRoutes } from './creatives';
import {
  newCompanyCampaignPath,
  showCompanyCampaignPath,
  editCompanyCampaignPath
} from './path';

export const companyCampaignsRoutes = [
  {
    name: "キャンペーン作成",
    path: newCompanyCampaignPath,
    component: New,
    auth: true,
    visible: false
  },
  {
    name: "キャンペーン詳細",
    path: showCompanyCampaignPath,
    component: Show,
    auth: true,
    visible: false
  },
  {
    name: "キャンペーン編集",
    path: editCompanyCampaignPath,
    component: Edit,
    auth: true,
    visible: false
  },
  ...companyCampaignCreativesRoutes
]

