import { FC, useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import DateTime from 'components/DateTime';
import Selectize, { Item } from 'components/Selectize';
import { useFetchPrefectures } from 'hooks/api/useFetchPrefectures';
import { useFetchAreas } from 'hooks/api/useFetchAreas';
import { CreateFormData, CreateFormError } from './CreateForm';
import { UpdateFormData, UpdateFormError } from './UpdateForm';

import { SchoolSpecialityClassification } from 'hooks/api/admin/schedules/type';

type FormData = CreateFormData | UpdateFormData
type FormError = CreateFormError | UpdateFormError

type FormProps = {
  formData: FormData;
  setFormData: (values: FormData) => void;
  errors: FormError;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

const CLASSIFICATION_MAP = {
  arts: '文系',
  sciences: '理系',
  others: 'その他'
}

export const ScheduleForm: FC<FormProps> = ({
  formData,
  setFormData,
  errors,
  onSubmit,
  onClose,
}) => {
  type RequestData = typeof formData

  const { data: prefectureData } = useFetchPrefectures(true);
  const { data: areaData } = useFetchAreas(true);
  const schoolSpecialityClassifications: SchoolSpecialityClassification[] = ['arts', 'sciences', 'others'];
  const [targetPrefectures, setTargetPrefectures] = useState<Item<string>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  const setTargetSpecialityClassifications = (isChecked: boolean, value: SchoolSpecialityClassification) => {
    const data = { ...formData }
    const currentClassifications = data.targetSpecialityClassifications
    const newClassifications = isChecked ? [...currentClassifications, value]
                                         : currentClassifications.filter(classifications => classifications != value)
    data.targetSpecialityClassifications = newClassifications

    setFormData(data);
  }

  const onSelectTargetPrefectureIds = (item: Item<string>): void => {
    const data = { ...formData }
    data.targetPrefectureIds.push(item.value)
    setFormData(data);
  }

  const removeTargetPrefectureIds = (item: Item<string>): void => {
    const data = { ...formData }
    data.targetPrefectureIds = data.targetPrefectureIds.filter(prefectureId => prefectureId != item.value)
    setFormData(data);
  }

  useEffect(() => {
    if (prefectureData?.prefectures) {
      const items = prefectureData.prefectures.map<Item<string>>(prefecture => ({
        id: Number(prefecture.id),
        label: prefecture.name,
        value: prefecture.id,
        isDisplay: true,
        isSelected: formData.targetPrefectureIds.includes(prefecture.id)
      }))

      setTargetPrefectures(items)
    }
  }, [prefectureData])

  const handleSubmit = async () => {
    setLoading(true)
    await onSubmit()
    setLoading(false)
  }

  return (
    <Form className='col-6'>
      <Form.Group>
        <Form.Label>エリア</Form.Label>
        <Form.Control
          as="select"
          custom
          onChange={ e => onChange(e.target.value, 'areaId') }
          value={ formData.areaId || '' }
          isInvalid={ errors?.areaId && errors.areaId.length > 0 }
        >
          <option></option>
          { areaData?.areas && areaData.areas.map(area => <option key={ `area-${area.id}` } value={ area.id }>{ area.name }</option>) }
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          { errors?.areaId?.map((err, i) => <p key={ `error-areaId-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='scheduleFormIsSchedule'>
        <Form.Label>日程有無</Form.Label>
        <div>
          <Form.Check
            inline
            id='isScheduleTrue'
            type={ 'radio' }
            name='isSchedule'
            label='有'
            onChange={ _e => onChange(true, 'isSchedule') }
            checked={ formData.isSchedule }
          />
          <Form.Check
            inline
            id='isScheduleFalse'
            type={ 'radio' }
            name='isSchedule'
            label='無'
            onChange={ _e => onChange(false, 'isSchedule') }
            checked={ !formData.isSchedule }
          />
        </div>
      </Form.Group>

      { formData.isSchedule ? (
        <>
          <Form.Group>
            <Form.Label>開始日時</Form.Label>
            <DateTime
              className={`col-6 p-0`}
              isInvalid={ errors?.startAt && errors.startAt?.length > 0 }
              name='startAt'
              onChange={ value => onChange(value, "startAt") }
              value={ formData.startAt || '' }
            />
            <span className="invalid-feedback">
              { errors?.startAt?.map((err, i) => <p key={ `error-startAt-${i}` }>{ err }</p>) }
            </span>
          </Form.Group>

          <Form.Group>
            <Form.Label>終了日時</Form.Label>
            <DateTime
              className={`col-6 p-0`}
              isInvalid={ errors.endAt && errors.endAt?.length > 0 }
              name='endAt'
              onChange={ value => onChange(value, "endAt") }
              value={ formData.endAt }
            />
            <span className="invalid-feedback">
              { errors?.endAt?.map((err, i) => <p key={ `error-endAt-${i}` }>{ err }</p>) }
            </span>
          </Form.Group>
        </>
      ) : (
        <Form.Group>
          <Form.Label>日程無しテキスト</Form.Label>
          <Form.Control
            type='text'
            name="noScheduleText"
            onChange={ e => onChange(e.target.value, 'noScheduleText') }
            value={ formData.noScheduleText || '' }
            isInvalid={ errors?.noScheduleText && errors.noScheduleText?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors?.noScheduleText?.map((err, i) => <p key={ `error-noScheduleText-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>
      )}

      <Form.Group controlId='scheduleFormIsSchedule'>
        <Form.Label>開催タイプ</Form.Label>
        <div>
          <Form.Check
            inline
            type={ 'radio' }
            name='type'
            label='オフライン'
            checked={ formData.type == 'OfflineSchedule' }
            onChange={ _e => onChange('OfflineSchedule', 'type') }
          />
          <Form.Check
            inline
            type={ 'radio' }
            name='type'
            label='オンライン'
            checked={ formData.type == 'OnlineSchedule' }
            onChange={ _e => onChange('OnlineSchedule', 'type') }
          />
        </div>
      </Form.Group>

      { formData.type == 'OnlineSchedule' ? (
        <Form.Group>
          <Form.Label>オンラインテキスト(Web会議URL等)</Form.Label>
          <Form.Control
            as='textarea'
            name="onlineText"
            onChange={ e => onChange(e.target.value, 'onlineText') }
            value={ formData.onlineText }
            isInvalid={ errors?.onlineText && errors.onlineText?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors?.onlineText?.map((err, i) => <p key={ `error-onlineText-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
      </Form.Group>
      ) : (
        <>
          <Form.Group>
            <Form.Label>郵便番号</Form.Label>
            <Form.Control
              type='text'
              name="postcode"
              onChange={ e => onChange(e.target.value, 'postcode') }
              value={ formData.postcode }
              isInvalid={ errors?.postcode && errors.postcode?.length > 0 }
            />
            <Form.Control.Feedback type="invalid">
              { errors?.postcode?.map((err, i) => <p key={ `error-postCode-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>都道府県</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={ e => onChange(e.target.value, 'prefectureId') }
              value={ formData.prefectureId || '' }
              isInvalid={ errors?.prefectureId && errors.prefectureId?.length > 0 }
            >
              <option></option>
              { prefectureData?.prefectures && prefectureData.prefectures.map(prefecture => (
                <option
                  key={ `prefecture-${prefecture.id}` }
                  value={ prefecture.id }
                >
                  { prefecture.name }
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              { errors?.prefectureId?.map((err, i) => <p key={ `error-prefectureId-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>住所1</Form.Label>
            <Form.Control
              type='text'
              name="city"
              onChange={ e => onChange(e.target.value, 'city') }
              value={ formData.city }
              isInvalid={ errors?.city && errors.city?.length > 0 }
            />
            <Form.Control.Feedback type="invalid">
              { errors?.city?.map((err, i) => <p key={ `error-city-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>住所2</Form.Label>
            <Form.Control
              type='text'
              name="building"
              onChange={ e => onChange(e.target.value, 'building') }
              value={ formData.building }
              isInvalid={ errors?.building && errors.building?.length > 0 }
            />
            <Form.Control.Feedback type="invalid">
              { errors?.building?.map((err, i) => <p key={ `error-building-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>最寄り駅</Form.Label>
            <Form.Control
              type='text'
              name="station"
              onChange={ e => onChange(e.target.value, 'station') }
              value={ formData.station }
              isInvalid={ errors?.station && errors.station?.length > 0 }
            />
            <Form.Control.Feedback type="invalid">
              { errors?.station?.map((err, i) => <p key={ `error-station-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      <Form.Group>
        <Form.Label>備考</Form.Label>
        <Form.Control
          as='textarea'
          name="remarks"
          onChange={ e => onChange(e.target.value, 'remarks') }
          value={ formData.remarks }
          isInvalid={ errors?.remarks && errors.remarks?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors?.remarks?.map((err, i) => <p key={ `error-remarks-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>参加上限</Form.Label>
        <Form.Control
          type='number'
          name="participantLimit"
          onChange={ e => onChange(e.target.value, 'participantLimit') }
          value={ formData.participantLimit }
          isInvalid={ errors?.participantLimit && errors.participantLimit?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors?.participantLimit?.map((err, i) => <p key={ `error-participantLimit-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>公開開始日時</Form.Label>
        <DateTime
          className={`col-6 p-0`}
          name='publicStartAt'
          onChange={ value => onChange(value, "publicStartAt") }
          value={ formData.publicStartAt }
          isInvalid={ errors?.publicStartAt && errors.publicStartAt?.length > 0 }
        />
        <span className="invalid-feedback">
          { errors?.publicStartAt?.map((err, i) => <p key={ `error-publicStartAt-${i}` }>{ err }</p>) }
        </span>
      </Form.Group>

      <Form.Group>
        <Form.Label>公開終了日時</Form.Label>
        <DateTime
          className={`col-6 p-0`}
          name='publicEndAt'
          onChange={ value => onChange(value, "publicEndAt") }
          value={ formData.publicEndAt }
          isInvalid={ errors?.publicEndAt && errors.publicEndAt?.length > 0 }
        />
        <span className="invalid-feedback">
          { errors?.publicEndAt?.map((err, i) => <p key={ `error-publicEndAt-${i}` }>{ err }</p>) }
        </span>
      </Form.Group>

      <Form.Group controlId='scheduleFormTargetShooleSpecialityGroups'>
        <Form.Label>文理制限</Form.Label>
        <div>
          { schoolSpecialityClassifications.map(classification => (
            <Form.Check
              key={ `school-speciality-groups-${classification}`}
              id={classification}
              inline
              type={ 'checkbox' }
              name='targetSchoolSpecialityClassification'
              label={ CLASSIFICATION_MAP[classification] }
              checked={ formData.targetSpecialityClassifications.includes(classification) }
              onChange={ e => setTargetSpecialityClassifications(e.target.checked, classification) }
            />
          )) }
        </div>
      </Form.Group>

      <Form.Group>
        <Form.Label>都道府県制限</Form.Label>
        <Selectize
          id={ 'prefectures' }
          items={ targetPrefectures }
          setItems={ setTargetPrefectures }
          onRemove={ removeTargetPrefectureIds }
          onSelect={ onSelectTargetPrefectureIds }
          placeholder={ '都道府県を入力してください' }
          isInvalid={ errors?.targetPrefectureIds && errors.targetPrefectureIds?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors?.targetPrefectureIds?.map((err, i) => <p key={ `error-targetPrefectureIds-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='scheduleFormIsSchedule'>
        <Form.Label>公開</Form.Label>
        <div>
          <Form.Check
            inline
            id='isPublishedTrue'
            type={ 'radio' }
            name='isPublished'
            label='公開'
            checked={ formData.isPublished }
            onChange={ _e => onChange(true, 'isPublished') }
          />
          <Form.Check
            inline
            id='isPublishedFalse'
            type={ 'radio' }
            name='isPublished'
            label='非公開'
            checked={ !formData.isPublished }
            onChange={ _e => onChange(false, 'isPublished') }
          />
        </div>
      </Form.Group>

      <Form.Group>
        <Button type="button" className='mr-2' variant="outline-secondary" onClick={ onClose }>キャンセル</Button>
        <Button
          type="button"
          variant='outline-success'
          disabled={ loading }
          onClick={ async (_) => await handleSubmit() }
        >
          保存
        </Button>
      </Form.Group>
    </Form>
  )
}
