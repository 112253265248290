import React, { Dispatch, FC, useEffect, useState, } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Operation, getOperations } from 'libs/apiClient/admin/permissionGroups/operations'
import { Errors, RequestData } from 'libs/apiClient/admin/permissionGroups';

type FormProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
  onSubmit: () => void;
}

const PermissionGroupForm: FC<FormProps>= (props) => {
  const { formData, setFormData, errors, onSubmit } = props;
  const [operations, setOperations] = useState<Operation[]>([])

  const onChange = (value: string, key: 'name' | 'description'): void => {
    const data = { ...formData }
    data[key] = value;

    setFormData(data);
  }

  const onChangeChecks = (value :string, checked: boolean): void => {
    const data = { ...formData }

    const sv = value.split('#');
    if (checked) {
      data.authorizations?.push({ controller: sv[0], action: sv[1] });
    } else {
      data.authorizations = data.authorizations?.filter(op => op.controller != sv[0] || op.action != sv[1])
    }

    setFormData(data);
  }

  const isChecked = (
    controller: string, action: string,
    authorizations?: { controller: string, action: string }[],
    isManage?: boolean,
  ): boolean => {
    if (isManage === false) return true;
    if (authorizations === undefined) return false;
    return authorizations.some( a => a.controller === controller && a.action === action)
  }

  const isDisabled = (isManage?: boolean): boolean => isManage === false

  useEffect(() => {
    getOperations(setOperations);
  }, [])

  return (
    <Form>
      <div className="d-flex flex-row-reverse">
      <Button type="button" onClick={ onSubmit } variant='outline-success'>
          登録
        </Button>
      </div>

      <Form.Group controlId='name'>
        <Form.Label>権限グループ名</Form.Label>
        <Form.Control
          type='text'
          name='name'
          value={ formData.name }
          onChange={ e => onChange(e.currentTarget.value, "name") }
          isInvalid={ errors.name?.length > 0 }
        />
        <Form.Control.Feedback type='invalid'>
          { errors.name?.map(err => <p>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='description'>
        <Form.Label>説明</Form.Label>
        <Form.Control
          type='text'
          name='description'
          value={ formData.description }
          onChange={ e => onChange(e.currentTarget.value, "description") }
          isInvalid={ errors.description?.length > 0 }
        />
        <Form.Control.Feedback type='invalid'>
          { errors.description?.map(err => <p>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <div className="border pb-4">
      {
        operations.map(operation => {
          return (
            <div className="mx-3 mt-4" key={operation.controller}>
              <div className="mb-3">{operation.name}</div>
              <div className="bg-light pb-3">
              {operation.actions.map(action => {
                return (
                  <div
                    className="d-flex mx-4 pt-3 align-middle"
                    key={operation.controller + '#' + action.action}
                  >
                      <div className="mr-auto">{action.name}</div>
                      <Form.Check
                        type="checkbox"
                        label="許可"
                        name="authorizations"
                        value={operation.controller + '#' + action.action}
                        checked={isChecked(operation.controller, action.action, formData.authorizations, action.isManage)}
                        disabled={isDisabled(action.isManage)}
                        onChange={ e => onChangeChecks(e.target.value, e.target.checked) }
                      />
                  </div>
                )
              })}
              </div>
            </div>
          )
        })
      }
      </div>
    </Form>
  )
}

export default PermissionGroupForm;
