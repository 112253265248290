import { FC } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Users from './components';

const Index: FC = () => {
  return (
    <Layout>
      <PageTitle title="ユーザー管理"/>
      <Users/>
    </Layout>
  )
}

export default Index;
