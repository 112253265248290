import { get, post, patch } from "libs/fetch";

export const showPath = (id: string | number) => `api/v1/admin/administrators/${id}`

export type PermissionGroup = {
  id: number;
  name: string;
  description: string;
}

export type Administrator = {
  id: number,
  name: string,
  email: string,
  permissionGroup?: PermissionGroup,
}

export type RequestData = {
  name?: string;
  email?: string;
  permissionGroupId?: number;
}

export type CreateResponse = {
  id: number;
}

export type UpdateResponse = {
  id: number;
  name: string;
  email: string;
  permissionGroup?: PermissionGroup;
}

export type ShowResponse = {
  id: number;
  name: string;
  email: string;
  permissionGroup?: PermissionGroup;
}

export type Errors = {
  name: string[];
  email: string[];
  permissionGroupId: string[];
}

export const RequestTemplate = {
  name: '',
  email: '',
  permissionGroupId: 0,
}

export const ErrorsTemplate = {
  name: [],
  email: [],
  permissionGroupId: [],
}

export const index = async(
  callback: (admins: Administrator[]) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get('api/v1/admin/administrators')
  const body = await res.json();

  if (res.status === 200) {
    const administrators = body;
    callback(administrators);
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

export const create = async(
  data: RequestData,
  callback: (responseData: CreateResponse) => void,
  errCallback: (error: Errors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await post('api/v1/admin/administrators', { administrator: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: CreateResponse = body.administrator;
    callback(responseData);
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}

export const update = async(
  id: number | string,
  data: RequestData,
  callback: (responseData: UpdateResponse) => void,
  errCallback: (error: Errors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await patch(`api/v1/admin/administrators/${id}`, { administrator: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: UpdateResponse = body.administrator;
    callback(responseData);
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}

export const show = async(
  id: number | string,
  callback: (responseData: ShowResponse) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get(`api/v1/admin/administrators/${id}`);
  const body = await res.json();

  if (res.status === 200) {
    const responseData: ShowResponse = body;
    callback(responseData);
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}
