import { useState } from 'react'
import { patch } from 'libs/fetch'

export const usePatch = <RequestBody>(
  path: string,
) => {
  const [loading, setLoading] = useState<boolean>(false)

  const update = async (requestBody: RequestBody) => {
    setLoading(true)
    const response = await patch<RequestBody>(path, requestBody)
    setLoading(false)

    return response
  }

  return { loading, update }
}