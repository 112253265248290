import { FC } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { routes } from 'routes/route'
import style from './Sidebar.module.scss';

const Sidebar: FC = () => {
  const location = useLocation();

  const activeRoute = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <ul className={`flex-column ${style.linkContainer}`}>
      {routes.filter(route => route.visible).map((prop, key) => {
        return (
          <li key={`side-link-${key}`} className={ `${activeRoute(prop.path)}` }>
            <NavLink
              to={prop.path}
              className={`nav-link ${style.linkText}`}
              activeClassName="active"
            >
              { prop.name }
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}

export default Sidebar;
