import { useFetch } from 'hooks/useFetch'

type ResponseBody = { companies: Company[] }

type Company = {
  id: number,
  name: string,
  clients: [],
  campaignSize: number
}


export const useFetchCompanies = (isImmediate = false) => {
  const path = 'api/v1/admin/companies'
  return useFetch<ResponseBody>(path, isImmediate)
}
