import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { useHistory } from 'react-router';
import { showTagsPathCreator } from 'routes/tags/path';
import { create, CreateData, CreateErrors, CreateResponse } from 'libs/apiClient/admin/tags';
import TagForm from '../components/TagForm';
import Forbidden from 'pages/forbidden';

const New: FC = () => {
  const history = useHistory()
  const [createData, setCreateData] = useState<CreateData>({ name: '' });
  const [errors, setErrors] = useState<CreateErrors>({ name: [] });
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  const onSubmit = async () => {
    const callback = (res: CreateResponse) => {
      history.push(showTagsPathCreator(res.id))
    }

    const errCallback = (err: CreateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await create(createData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="タグ登録" />
        <Panel className="col-6">
          <TagForm
            formData={createData}
            setFormData={setCreateData}
            errors={errors}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default New;