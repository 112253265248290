export const indexPath = (userId: string | number | null = null): string => {
  const queryParams = userId ? new URLSearchParams([['user_id', String(userId)]]) : '';
  return `api/v1/admin/entries?${queryParams}`;  
}
export const createPath = 'api/v1/admin/entries';
export const updatePath = (id: string | number) => `api/v1/admin/entries/${id}`

export type Entry = {
  id: number;
  status: string;
  campaignName: string;
  schedule: Schedule;
}

export type RequestData = {
  userId: number | string | null;
  scheduleId: number |  string | null;
  entryRoute: string;
  customFormItemAnswers: customFormItemAnswers[];
}

export type Schedule = {
  id: number;
  isOnline: boolean;
  isSchedule: boolean;
  areaId: number;
  address1: string;
  address2: string;
  startAt: string;
  endAt: string;
}

export type customFormItemAnswers = {
  customFormItemId: number | string;
  value: number | string | null;
}

export type Response = {
  id: number | string;
}

export type Errors = {
  userId?: string[];
  scheduleId?: string[];
  entryRoute?: string[];
  entryConditions?: string[];
  customFormItemAnswers?: string[];
  gender?: string[];
  schoolId?: string[];
  schoolSpecialtyGroupId?: string[];
}
