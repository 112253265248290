import { useState, useEffect } from 'react';
import { useFetchUniversityDepartments } from './api/universityDepartments'

type Option = {
  id: string | number,
  value: string | number,
  label: string
}

type UseUniversityDepartmentOptions = () => {
  reloadUniversityDepartmentOptions: (universityFacultyId: string | number) => void;
  universityDepartmentOptions: Option[]
};

export const useUniversityDepartmentOptions: UseUniversityDepartmentOptions = () => {
  const { universityDepartments, fetchUniversityDepartments } = useFetchUniversityDepartments();
  const [universityDepartmentOptions, setUniversityDepartmentOptions] = useState<Option[]>([]);

  const reloadUniversityDepartmentOptions = async (universityFacultyId: string | number) => {
    fetchUniversityDepartments(universityFacultyId)
  }

  useEffect(() => {
    if (universityDepartments) {
      const options = universityDepartments.map(department => (
        {
          id: department.id,
          value: department.id,
          label: department.name,
        }
      ))

      setUniversityDepartmentOptions(options)
    }
  }, [universityDepartments])

  return {
    reloadUniversityDepartmentOptions,
    universityDepartmentOptions
  };
};
