export const updatePath = () => 'api/v1/admin/password'

export type RequestData = {
  resetPasswordToken: string;
  password: string;
  passwordConfirmation: string;
}

export type Response = {
  message: string;
}

export type Errors = {
  resetPasswordToken?: string[];
  password?: string[];
  passwordConfirmation?: string[];
}
