import { usePost } from 'hooks/usePost'
import { ScheduleType, SchoolSpecialityClassification } from './type'

export type CreateResponseBody = {
  schedule: { id: string }
}

export type CreateErrorResponse = {
  errors: {
    campaignId?: string[],
    type?: string[],
    onlineText?: string[],
    prefectureId?: string[],
    postcode?: string[],
    city?: string[],
    building?: string[],
    station?: string[],
    isSchedule?: string[],
    noScheduleText?: string[],
    startAt?: string[],
    endAt?: string[],
    isPublished?: string[],
    publicStartAt?: string[],
    publicEndAt?: string[],
    participantLimit?: string[],
    remarks?: string[],
    areaId?: string[],
    targetPrefectureIds?: string[],
    targetSpecialtyClassifications?: string[]
  }
}

export type CreateRequestBody = {
  schedule: {
    type: ScheduleType,
    onlineText: string,
    prefectureId: string,
    postcode: string,
    city: string,
    building: string,
    station: string,
    isSchedule: boolean,
    noScheduleText: string,
    startAt: string,
    endAt: string,
    isPublished: boolean,
    publicStartAt: string,
    publicEndAt: string,
    participantLimit?: number,
    remarks?: string
    areaId: string,
    targetPrefectureIds: string[],
    targetSpecialityClassifications: SchoolSpecialityClassification[]
  }
}

export const useCreateSchedules = (campaignId: string) => {
  const path = `api/v1/admin/campaigns/${campaignId}/schedules`
  return usePost<CreateRequestBody>(path)
}
