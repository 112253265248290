import React, { useState, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoadSpinner from 'components/LoadSpinner';
import _ from 'lodash';
import { get } from 'libs/fetch';

const ResetPasswordRoute: React.FC<RouteProps> = props => {
  const [isAuth, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const innerComponent = (innerProps: RouteProps) => {
    return (
      isAuth ? (
        <Redirect to={{ pathname: "/", state: { from: innerProps.location } }} />
      ) : (
        <Route {...props} />
      )
    )
  }

  useEffect(() => {
    let unmounted = false;

    const authenticate = async () => {
      const status = await get('api/v1/admin/auth')
        .then(res => res.ok)
        .catch(() => false);

      if (!unmounted) {
        setLoading(false);
        setAuth(status);
      }
    }

    authenticate();

    return () => {
      unmounted = true
    }
  }, [])


  const rest = _.omit(props, ['component'])

  return (
    <div>
      {isLoading ? <LoadSpinner /> : <Route {...rest} render={innerProps => innerComponent(innerProps)} />}
    </div>
  );
}

export default ResetPasswordRoute;