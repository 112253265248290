export const createPath = (campaignId: string | number): string => `api/v1/admin/campaigns/${campaignId}/creatives`;
export const showPath = (id: string | number, campaignId: string | number): string => `api/v1/admin/campaigns/${campaignId}/creatives/${id}`;
export const updatePath = (id: string | number, campaignId: string | number): string => `api/v1/admin/campaigns/${campaignId}/creatives/${id}`;

export type Creative = {
  id: number;
  administrativeTitle: string;
  memo: string;
  title: string;
  abstruct: string;
  body: string;
  isListed: boolean;
  isPublished: boolean;
  thumbnail: { url: string };
  style: string;
  campaignId: string | number;
}

/* patch request */
export type RequestData = {
  administrativeTitle: string;
  memo: string;
  thumbnail: File | null;
  title: string;
  abstruct: string;
  body: string;
  style: string;
  isListed: boolean;
  isPublished: boolean;
  campaignId: string | number;
}

export type Response = {
  id: number;
}

export type Errors = {
  administrativeTitle?: string[];
  memo?: string[];
  title?: string[];
  abstruct?: string[];
  body?: string[];
  isListed?: string[];
  isPublished?: string[];
  thumbnail?: string[];
  style?: string[];
  campaignId?: string[];
}
