import React, { useState, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoadSpinner from 'components/LoadSpinner';
import _ from 'lodash';
import { get } from 'libs/fetch';

const NotFoundRoute: React.FC<RouteProps> = props => {
  return (
    <div>
      <Route {...props} />
    </div>
  );
}

export default NotFoundRoute;
