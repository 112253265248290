import { useState, useEffect } from 'react';
import { useFetchUniversityFaculties } from './api/universityFaculties';

type Option = {
  id: string | number,
  value: string | number,
  label: string
}

type UseUniversityFacultyOptions = () => {
  reloadUniversityFacultyOptions: (universityId: string | number) => void;
  universityFacultyOptions: Option[]
};

export const useUniversityFacultyOptions: UseUniversityFacultyOptions = () => {
  const { universityFaculties, fetchUniversityFaculties } = useFetchUniversityFaculties();
  const [universityFacultyOptions, setUniversityFacultyOptions] = useState<Option[]>([]);

  const reloadUniversityFacultyOptions = async (universityId: string | number) => {
    fetchUniversityFaculties(universityId)
  }

  useEffect(() => {
    if (universityFaculties) {
      const options = universityFaculties.map(faculty => (
        {
          id: faculty.id,
          value: faculty.id,
          label: faculty.name,
        }
      ))

      setUniversityFacultyOptions(options)
    }
  }, [universityFaculties])

  return {
    reloadUniversityFacultyOptions,
    universityFacultyOptions
  };
};
