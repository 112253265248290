import { FC, ReactNode } from 'react';
import {  Table as BootStrapTable } from 'react-bootstrap';
import style from './Table.module.scss';

type TableProps = {
  children: ReactNode;
  className?: string;
};

const Table: FC<TableProps> = (props) => {
  const { children, className } = props;

  return (
    <BootStrapTable
      className={ `${className} ${style.table}` }
      hover
    >
      { children }
    </BootStrapTable>
  );
}

Table.defaultProps = {
  className: ''
}

export default Table;

