import Index from 'pages/users/csv/index';
import Download from 'pages/users/csv/download';

import {
  indexUserCsvPath,
  downloadUserCsvPath
} from './path';

export const userCsvRoutes = [
  {
    name: "csv作成",
    path: indexUserCsvPath,
    component: Index,
    auth: true,
    visible: true
  },
  {
    name: "csv DL",
    path: downloadUserCsvPath,
    component: Download,
    auth: true,
    visible: false
  }
]
