import { useEffect, useState } from 'react';
import { get } from 'libs/fetch';
import {
  CustomFormItems,
  CustomFormItem
} from 'types/api/admin/customFormItems'

// TODO: エラー処理
export const useFetchCustomFormItems = (entryFormId: string) => {
  const [customFormItems, setCustomFormItems] = useState<CustomFormItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const fetch = async () => {
    const query = new URLSearchParams();
    query.append('entry_form_id', entryFormId);

    const response = await get(`api/v1/admin/custom_form_items?${query.toString()}`);

    if (response.ok) {
      const body: CustomFormItems = await response.json();
      setCustomFormItems(body.customFormItems)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [])

  return { customFormItems, isLoading, fetch }
}
