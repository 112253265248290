import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import { useUserCsv } from 'hooks/api/users/csv/ussUserCsv';
import { useLocation } from 'react-router';

const Index: FC = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const { fetchUserCsvDownloadLink } = useUserCsv();
  const [downloadLink, setDownloadLink] = useState<string>();
  const [isDownloadable, setIsDownloadable] = useState<boolean>(true);

  useEffect(() => {
    const fetchDownloadLink = async () => {
      if (token) {
        const { result, link } = await fetchUserCsvDownloadLink(token);
        if (result) {
          setDownloadLink(link)
        } else {
          setIsDownloadable(false)
        }
      }
    }

    fetchDownloadLink();
  }, [])

  return (
    <Layout>
      <PageTitle title="CSV ダウンロード"/>
      <Panel>
        {
          (downloadLink && isDownloadable) ? (
            <a href={downloadLink} download>ダウンロード</a>
          ) : (
            <span>トークンの期限が切れている可能性があります。再度csvの作成を行ってください。</span>
          )
        }
      </Panel>
    </Layout>
  )
}

export default Index;
