import React, { useState, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoadSpinner from 'components/LoadSpinner';
import _ from 'lodash';
import { get } from 'libs/fetch';

const PrivateRoute: React.FC<RouteProps> = props => {
  const [isAuth, setAuth] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const innerComponent = (innerProps: RouteProps) => {
    return (
      isAuth ? (
        <Route { ...props } />
      ) : (
        <Redirect to={ { pathname: "/login", state: { from: innerProps.location } } } />
      )
    )
  }

  useEffect(() => {
    let unmounted = false;

    const authenticate = async () => {
      const res = await get('api/v1/admin/auth');
      if (!unmounted) {
        setLoading(false);
        setAuth(res.ok);
      }
    }

    authenticate();

    return () => {
      unmounted = true
    }
  }, [])

  const rest = _.omit(props, ['component'])

  return (
    <div>
      { isLoading ? <LoadSpinner/> : <Route { ...rest } render={ innerProps => innerComponent(innerProps) } /> }
    </div>
  );
}

export default PrivateRoute;
