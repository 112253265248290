import { get } from 'libs/fetch';

export type Operation = {
  name: string,
  controller: string,
  actions: Action[],
}

export type Action = {
  name: string,
  action: string,
  isManage?: boolean,
}

export const getOperations = async (
  callback: (responseData: Operation[]) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get('api/v1/admin/permission_groups/operations');
  const body = await res.json();

  if (res.status === 200) {
    const responseData: Operation[] = body.operations;
    callback(responseData)
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}
