import { Prefecture } from 'libs/apiClient/prefectures';
import { University } from 'libs/apiClient/universities';
export type SpecialityClassification = 'arts' | 'sciences' | 'others'

export const updatePath = (id: string | number) => `api/v1/admin/inside_sales/users/${id}`

export type User = {
  userId: number;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefecture: Prefecture;
  gender: '男性' | '女性' | 'その他';
  specialityClassification: '文系' | '理系' | 'その他';
  universitySet?: {
    id: string;
    universityId: string;
    universityFacultyId: string;
    universityDepartmentId: string;
    admissionOn: string;
    graduateOn: string;
    degree: string;
    searchKey: string;
  };
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type RequestData = {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefectureId: number | null;
  gender: string;
  specialityClassification: SpecialityClassification;
  universitySet?: {
    id?: string;
    universityId: string;
    universityFacultyId: string;
    universityDepartmentId: string;
    admissionOn: string;
    graduateOn: string;
  }
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type Response = {
  id: number | string;
}

export type Errors = {
  gender?: string[];
  specialityClassification?: string[];
}
