import { FC, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import {indexPath, User} from 'libs/apiClient/admin/users';
import { get } from 'libs/apiClient';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import { UpdateForm } from './UpdateForm';
import Toast from 'components/Toast';

const Users: FC = () => {
  const [targetUser, setTargetUser] = useState<User>();
  const [users, setUsers] = useState<User[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string | number | null>(null);
  const [isDisplayToast, setIsDisplayToast] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const onSearch = () => {
    const callback = (res: User[]) => {
      setUsers(res);
      setTargetUser(undefined);
      if (res.length == 0) {
        setMessage('該当するユーザーは見つかりませんでした');
        setIsDisplayToast(true);
      }
    }

    get(indexPath(searchKeyword), 'users', callback)
  }

  return (
    <>
      <Panel className='pb-4'>
        <PanelTitle title='ユーザー検索' />
        <Form className='col-6'>
          <Form.Group controlId='userSearch'>
            <Form.Label>メールアドレス or 電話番号</Form.Label>
            <Form.Control
              type='text'
              name="userSearch"
              onChange={e => setSearchKeyword(e.target.value)}
            />
          </Form.Group>
          <Button type="button" variant='outline-primary' onClick={onSearch}>検索</Button>
        </Form>
      </Panel>

      <Panel className='pb-4'>
        <PanelTitle title='検索結果' />
        <Table className='mb-2'>
          <thead>
            <tr>
              <th>ID</th>
              <th>姓</th>
              <th>名</th>
              <th>メールアドレス</th>
              <th>電話番号</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(user =>
              <tr key={user.userId}>
                <td className={`align-middle`} >{user.userId}</td>
                <td className={`align-middle`} >{user.lastName}</td>
                <td className={`align-middle`} >{user.firstName}</td>
                <td className={`align-middle`} >{user.email}</td>
                <td className={`align-middle`} >{user.phoneNumber}</td>
                <td className={`align-middle`} >
                  <Button variant='dark' onClick={() => setTargetUser(user)}>
                    詳細
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {targetUser && <UpdateForm user={targetUser} onClose={() => setTargetUser(undefined)} />}
      </Panel>

      {isDisplayToast && (
        <Toast
          message={message}
          isDisplayToast={isDisplayToast}
          setIsDisplayToast={setIsDisplayToast}
        />
      )
      }
    </>
  )
}

export default Users;
