import { Dispatch, FocusEvent, ChangeEvent, useState, useEffect } from 'react';
import style from './Selectize.module.scss';
import { nanoid } from 'nanoid';

export type Item<T> = {
  id: number;
  label: string;
  value: T;
  isDisplay: boolean;
  isSelected: boolean;
}

// MEMO: idにnanoidを使用するとidが都度変わるため挙動がおかしくなる
type Props<T> = {
  id: string;
  items: Item<T>[];
  setItems: Dispatch<React.SetStateAction<Item<T>[]>>;
  placeholder?: string;
  onSelect: (item: Item<T>) => void;
  onRemove:(item: Item<T>) => void;
  isInvalid?: boolean;

}

const Selectize = <T extends any>(props: Props<T>) => {
  const { id, items, setItems, placeholder, onSelect, onRemove, isInvalid } = props;
  const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(false);
  const selectizeId = `selectize-${id}`;

  useEffect(() => {
    let isMounted = true;
    document.addEventListener('click', handleClick);
    return () => { isMounted = false };
  }, []);

  // 範囲外をクリックしたら閉じる
  const handleClick = (e: MouseEvent) => {
    const element = e.target as HTMLElement;
    if (!element.closest(`#${selectizeId}`)) {
      setDisplaySuggestions(false);
    }
  }

  const onFocusHandler = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    filter(value);
    setDisplaySuggestions(true);
  }

  const filter = (value: string) => {
    const updatedItems = items.map(item => {
      const regExp = new RegExp(value);
      item.isDisplay = regExp.test(item.label);

      return item;
    })

    setItems(updatedItems);
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    filter(value);
  }

  const onHandleSelecte = (id: number): void => {
    // selectedフラグを立てる
    const updatedItems = [...items];
    const index = updatedItems.findIndex(item => item.id === id);
    updatedItems[index].isSelected = true;
    setItems(updatedItems);

    onSelect(updatedItems[index]);
  }

  const onHandleRemove = (id: number): void => {
    // selectedフラグを立てる
    const updatedItems = [...items];
    const index = updatedItems.findIndex(item => item.id === id);
    updatedItems[index].isSelected = false;
    setItems(updatedItems);

    onRemove(updatedItems[index])
  }

  return (
    <div id={ selectizeId } className={ `${ isInvalid ? 'is-invalid' : ''}` }>
      <input
        placeholder={ placeholder }
        className={ `form-control` }
        type='text'
        onFocus={ onFocusHandler }
        onChange={ onChange }
      />
      { displaySuggestions &&
        <Suggestions
          items={ items }
          onClick={ onHandleSelecte }
        />
      }
      <Tips
        items={ items }
        onClick={ onHandleRemove }
      />
    </div>
  )
}

type SuggestionsProps<T> = {
  items: Item<T>[];
  onClick: (id: number) => void;
}

const Suggestions = <T extends any>(props: SuggestionsProps<T>) => {
  const { items, onClick } = props;
  const keyId = nanoid(8);

  return (
    <ul className={ `${style.suggestionBox}` }>
      {
        items.map((item, i) => {
          const { isDisplay, isSelected } = item;
          return (
            (isDisplay && !isSelected) && (
              <li
                className={ `${style.suggestion}` }
                key={`suggestion-${keyId}-${i}`}
                onClick={ _e => onClick(item.id) }
              >
                { item.label }
              </li>
            )
          )
        })
      }
    </ul>
  )
}

type TipsProps<T> = {
  items: Item<T>[];
  onClick: (id: number) => void;
}

const Tips = <T extends any>(props: TipsProps<T>) => {
  const { items, onClick } = props;
  const keyId = nanoid(8)

  return(
    <ul className={ `${style.tipBox}` }>
      {
        items.map((item, i) => {
          return(
            ( item.isSelected &&
              <li
                key={`tip-${keyId}-${i}`}
                className={ `${style.tip}` }
              >
                { item.label }
                <button
                  className={ `${style.removeBtn}` }
                  onClick={ _e => onClick(item.id) }
                >
                </button>
              </li>
            )
          )
        })
      }
    </ul>
  )
}

Selectize.defaultProps = {
  isInvalid: false
}

export default Selectize;