import Index from 'pages/tags/index';
import New from 'pages/tags/new';
import Show from 'pages/tags/show';
import Edit from 'pages/tags/edit';


import {
  indexTagsPath,
  newTagsPath,
  showTagsPath,
  editTagsPath
} from './path';

export const tagsRoutes = [
  {
    name: "タグ",
    path: indexTagsPath,
    component: Index,
    auth: true,
    visible: true
  },
  {
    name: "タグ作成",
    path: newTagsPath,
    component: New,
    auth: true
  },
  {
    name: "タグ詳細",
    path: showTagsPath,
    component: Show,
    auth: true
  },
  {
    name: "タグ編集",
    path: editTagsPath,
    component: Edit,
    auth: true
  }
]

