import { get } from 'libs/fetch';

export const indexPath = 'api/v1/prefectures';

export type Prefecture = {
  id: number;
  name: string;
}

// TODO: deplicated
export const index = async (callback: (responseData: Prefecture[]) => void) => {
  const res = await get('api/v1/prefectures');
  const body = await res.json();

  const responseData: Prefecture[] = body.prefectures;
  callback(responseData)
}