export const indexPath = 'api/v1/admin/creative_images';
export const createPath = 'api/v1/admin/creative_images';
export const showPath = (id: string | number): string => `api/v1/admin/creative_images/${id}`;

export type CreativeImage = {
  id: number | string;
  image: { url: string };
}

/* patch request */
export type RequestData = {
  image: File | null;
}

export type Response = {
  id: number;
  image: { url: string };
}

export type Errors = {
  image?: string[];
}
