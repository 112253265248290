import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Item } from 'components/Selectize';
import { useHistory } from 'react-router';
import { showEducationalBackgroundGroupsPathCreator } from 'routes/educationalBackgroundGroups/path';
import { getUniversities, University } from 'libs/apiClient/universities';
import {
  update,
  UpdateData,
  UpdateErrors,
  UpdateResponse,
  show,
  EducationalBackgroundGroup
} from 'libs/apiClient/admin/educationalBackgroundGroups';
import EducationalBackgroundGroupForm from '../components/EducationalBackgroundGroupForm';
import { RouteComponentProps } from 'react-router-dom';
import Forbidden from 'pages/forbidden';

type Universities = {
  [key: string]: number[];
} & Object;

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Edit: FC<urlProps> = (props) => {
  const history = useHistory()
  const [updateData, setUpdateData] = useState<UpdateData>({ name: '', universityIds: [] });
  const [errors, setErrors] = useState<UpdateErrors>({ name: [], universityIds: [] });
  const [universities, setUniversities] = useState<Item<number[]>[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const educationalBackgroundGroupId = props.match.params.id

  useEffect(() => {
    const getFormData = async () => {
      // TODO: ここめっちゃ汚いのでリファクタする
      let selectedUniversities: Universities = {}
      await show(educationalBackgroundGroupId, (responseData: EducationalBackgroundGroup) => {
        responseData.universities.forEach((university: University) => {
          if (selectedUniversities.hasOwnProperty(university.name)) {
            selectedUniversities[university.name].push(university.id);
          } else {
            selectedUniversities[university.name] = [university.id];
          }
        })

        const newUpdateData = {
          name: responseData.name,
          universityIds: responseData.universities.map(university => university.id)
        }

        setUpdateData(newUpdateData)
      })

      await getUniversities((responseData: University[]) => {
        const data: Universities = {};
        responseData.forEach((university: University) => {
          if (data.hasOwnProperty(university.name)) {
            data[university.name].push(university.id);
          } else {
            data[university.name] = [university.id];
          }
        })
        const selectedkeys = Object.keys(selectedUniversities);
        const universityList = Object.keys(data).map((key, i) => {
          return {
            id: i + 1,
            label: key,
            value: data[key],
            isDisplay: true,
            isSelected: selectedkeys.includes(key)
          }
        });

        setUniversities(universityList);
      });
    }

    getFormData();
  }, [])

  const onSubmit = async () => {
    const callback = (res: UpdateResponse) => {
      history.push(showEducationalBackgroundGroupsPathCreator(res.id))
    }

    const errCallback = (err: UpdateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await update(educationalBackgroundGroupId, updateData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="学歴グループ編集" />
        <Panel className="col-6">
          <EducationalBackgroundGroupForm
            formData={updateData}
            setFormData={setUpdateData}
            errors={errors}
            universities={universities}
            setUniversities={setUniversities}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Edit;