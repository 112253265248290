import { FC, Dispatch, useState, useEffect, } from "react";
import { Button, Form,  } from 'react-bootstrap';
import { RequestData, Errors, } from 'libs/apiClient/admin/administrators'
import { getPermissionGroups, PermissionGroup } from 'libs/apiClient/admin/permissionGroups';

type PermissionGroupOption = {
  value: number,
  label: string,
}

type FormProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
  onSubmit: () => void;
}

const AdminForm: FC<FormProps>= (props) => {
  const {
    formData,
    setFormData,
    errors,
    onSubmit,
  } = props;
  
  const [permissionGroupOptions, setPermissionGroupOptions] = useState<PermissionGroupOption[]>([])

  useEffect(() => {
    const shape = (res: PermissionGroup[]) => {
      const options: PermissionGroupOption[] = [
        { value: 0, label: ''} // for unselected
      ];

      res.forEach((permissionGroup: PermissionGroup) => {
        options.push({
          value: permissionGroup.id,
          label: permissionGroup.name,
        });
      })
      setPermissionGroupOptions(options);
    }
    getPermissionGroups(shape);
  }, [])

  const onChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  return (
    <Form>
      <Form.Group controlId='name'>
        <Form.Label>管理者名</Form.Label>
        <Form.Control
          type='text'
          name="name"
          value={ formData.name }
          onChange={ e => onChange(e.currentTarget.value, "name") }
          isInvalid={ errors.name?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.name?.map(err => <p>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='email'>
        <Form.Label>メールアドレス</Form.Label>
        <Form.Control
          type='text'
          name="email"
          value={ formData.email }
          placeholder="admin@mail.com"
          onChange={ e => onChange(e.currentTarget.value, "email") }
          isInvalid={ errors.email?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.email?.map(err => <p>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='permissionGroupId'>
        <Form.Label>権限グループ</Form.Label>
        <Form.Control
          as='select'
          name="permissionGroupId"
          value={ formData.permissionGroupId }
          onChange={ e => onChange(e.currentTarget.value, "permissionGroupId") }
          isInvalid={ errors.permissionGroupId?.length > 0 }
        >
          { permissionGroupOptions?.map(o => <option value={o.value}>{o.label}</option>) }
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          { errors.permissionGroupId?.map(err => <p>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="button" onClick={ onSubmit } variant='outline-success'>
        登録
      </Button>
    </Form>
  )
}

export default AdminForm;