import { FC, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { indexAdminsPath } from 'routes/admins/path';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import AdminForm from '../components/AdminForm';
import {
  show, ShowResponse, update, UpdateResponse,
  RequestData, Errors, RequestTemplate, ErrorsTemplate
} from 'libs/apiClient/admin/administrators';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Edit: FC<urlProps> = (props) => {
  const history = useHistory()
  const [updateData, setUpdateData] = useState<RequestData>({ ...RequestTemplate });
  const [errors, setErrors] = useState<Errors>({ ...ErrorsTemplate });
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const id = props.match.params.id

  const forbiddenCallback = () => { setIsForbidden(true); }

  useEffect(() => {
    const setShowResponse = (showResponse: ShowResponse) => {
      setUpdateData({
        name: showResponse.name,
        email: showResponse.email,
        permissionGroupId: showResponse.permissionGroup?.id,
      });
    }

    show(id, setShowResponse, forbiddenCallback);
  }, [])

  const onSubmit = async () => {
    const callback = (res: UpdateResponse) => {
      history.push(indexAdminsPath)
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    await update(id, updateData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="管理者編集" />
        <Panel>
          <AdminForm
            formData={updateData}
            setFormData={setUpdateData}
            errors={errors}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Edit;