import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Button, Form } from 'react-bootstrap';
import { showPath, updatePath, RequestData, Errors, Response } from 'libs/apiClient/admin/companies';
import { RouteComponentProps, useHistory } from 'react-router';
import { indexCompaniesPath } from 'routes/companies/path';
import { get, patch } from 'libs/apiClient';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Edit: FC<urlProps> = (props) => {
  const history = useHistory()
  const [formData, setFormData] = useState<RequestData>({ name: '' });
  const [errors, setErrors] = useState<Errors>({ name: [] });
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const id = props.match.params.id;

  useEffect(() => {
    get(showPath(id), 'company', setFormData)
  }, [])

  const onChange = (value: string, key: 'name'): void => {
    const data = { ...formData }
    data[key] = value;

    setFormData(data);
  }

  const onSubmit = async () => {
    const callback = (_params: Response) => {
      history.push(indexCompaniesPath)
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await patch(updatePath(id), { company: formData }, 'company', callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="企業編集" />
        <Panel className="col-6">
          {formData &&
            <Form noValidate>
              <Form.Group controlId='formBasicName'>
                <Form.Label>企業名</Form.Label>
                <Form.Control
                  type='text'
                  name="name"
                  placeholder="〇〇株式会社"
                  onChange={e => onChange(e.currentTarget.value, "name")}
                  defaultValue={formData.name}
                  isInvalid={errors.name && errors['name'].length > 0}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.map(err => <p>{err}</p>)}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="button" onClick={onSubmit} variant='outline-success'>
                登録
              </Button>
            </Form>
          }
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Edit;