import { useState, useEffect } from 'react'
import { get } from 'libs/fetch'
import { indexPath, Prefecture } from 'libs/apiClient/prefectures'

export const useFetchPrefectures = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [prefectures, setPrefectures] = useState<Prefecture[]>([])

  const fetchPrefectures = async () => {
    try {
      const res = await get(indexPath)
      const body = await res.json()

      setPrefectures(body.prefectures as Prefecture[])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPrefectures()
  }, [])

  return { fetchPrefectures, prefectures, loading }
}
