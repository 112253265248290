import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Item } from 'components/Selectize';
import { useHistory } from 'react-router';
import { showAreasPathCreator } from 'routes/areas/path';
import { index, Prefecture } from 'libs/apiClient/prefectures';
import { show, Area, update, UpdateData, UpdateErrors, UpdateResponse } from 'libs/apiClient/admin/areas';
import AreaForm from '../components/AreaForm';
import { RouteComponentProps } from 'react-router-dom';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Edit: FC<urlProps> = (props) => {
  const history = useHistory()
  const [updateData, setUpdateData] = useState<UpdateData>({ name: '', prefectureIds: [] });
  const [errors, setErrors] = useState<UpdateErrors>({ name: [], prefectureIds: [] });
  const [prefectures, setPrefectures] = useState<Item<number>[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const areaId = props.match.params.id

  useEffect(() => {
    const getFormData = async () => {
      let selectedPrefectures: number[] = [];
      show(areaId, (res: Area) => {
        selectedPrefectures = res.prefectures.map(prefecture => prefecture.id);

        const newUpdateData = {
          name: res.name,
          prefectureIds: selectedPrefectures
        };

        setUpdateData(newUpdateData);
      })

      index((res: Prefecture[]) => {
        setPrefectures(res.map(prefecture => {
          return {
            id: prefecture.id,
            label: prefecture.name,
            value: prefecture.id,
            isDisplay: true,
            isSelected: selectedPrefectures.includes(prefecture.id)
          }
        }))
      })
    }

    getFormData();
  }, [])

  const onSubmit = async () => {
    const callback = (res: UpdateResponse) => {
      history.push(showAreasPathCreator(res.id))
    }

    const errCallback = (err: UpdateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await update(areaId, updateData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="エリア編集" />
        <Panel className="col-6">
          <AreaForm
            formData={updateData}
            setFormData={setUpdateData}
            errors={errors}
            prefectures={prefectures}
            setPrefectures={setPrefectures}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Edit;