import { useCallback, useState } from 'react';
import { get } from 'libs/fetch'

type University = {
  id: number;
  name: string;
  nameKana: string,
  degree: string;
  searchKey: string;
}

export const useUniversities = () => {
  const [universities, setUniversities] = useState<University[]>([])

  const fetchUniversities = useCallback(async (searchKey: string, degree: string) => {
    const response = await get(`/api/v1/universities?search_key=${searchKey}&degree=${degree}`)

    if (response.ok) {
      const body: { universities: University[] } = await response.json();
      setUniversities(body.universities);
      return
    }

    const error: Error = new Error('エラーになりました');
    throw error;
  }, []);

  return { universities, fetchUniversities }
};
