export const createPath = '/api/v1/admin/thanks_mails';
export const showPath = (id: string | number): string => `api/v1/admin/thanks_mails/${id}`;
export const updatePath = (id: string | number): string => `api/v1/admin/thanks_mails/${id}`;

export type ThanksMail = {
  id: number;
  title: string;
  body: string;
  htmlBody?: string;
  campaignId: string | number;
}

/* patch request */
export type RequestData = {
  title: string;
  body: string;
  htmlBody?: string;
  campaignId: string | number;
}

export type Response = {
  id: number;
}

export type Errors = {
  title?: string[];
  body?: string[];
  htmlBody?: string[];
  campaignId?: string[];
}
