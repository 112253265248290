import Index from 'pages/permissionGroups/index';
import New from 'pages/permissionGroups/new';
import Edit from 'pages/permissionGroups/edit';

import { indexPermissionGroupsPath, newPermissionGroupsPath, editPermissionGroupsPath } from './path';

export const permissionGroupsRoutes = [
  {
    name: "権限グループ",
    path: indexPermissionGroupsPath,
    component: Index,
    auth: true,
    visible: true
  },
  {
    name: "権限グループ登録",
    path: newPermissionGroupsPath,
    component: New,
    auth: true
  },
  {
    name: "権限グループ編集",
    path: editPermissionGroupsPath,
    component: Edit,
    auth: true
  },
]
