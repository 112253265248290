import Index from 'pages/users/index';
import { userEntriesRoutes } from './entries';
import { userCsvRoutes } from './csv';
import {
  indexUsersPath
} from './path';

export const usersRoutes = [
  {
    name: "ユーザー",
    path: indexUsersPath,
    component: Index,
    auth: true,
    visible: true
  },
  ...userEntriesRoutes,
  ...userCsvRoutes
]
