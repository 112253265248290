import { FC, useState, } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import PermissionGroupForm from '../components/PermissionGroupForm'
import Forbidden from 'pages/forbidden';
import { useHistory } from 'react-router';
import {
  createPermissionGroup, Response, 
  RequestData, RequestTemplate, Errors, ErrorsTemplate,
} from 'libs/apiClient/admin/permissionGroups'
import { indexPermissionGroupsPath } from 'routes/permissionGroups/path';

const New: FC = () => {
  const history = useHistory();
  const [requestData, setRequestData] = useState<RequestData>({...RequestTemplate});
  const [errors, setErrors] = useState<Errors>({...ErrorsTemplate});
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  const onSubmit = async() => {
    const callback = (res: Response) => {
      history.push(indexPermissionGroupsPath);
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }
    const forbiddenCallback = () => { setIsForbidden(true); }

    await createPermissionGroup(requestData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="権限グループ登録" />
        <Panel>
          <PermissionGroupForm 
            formData={ requestData }
            setFormData={ setRequestData }
            errors={ errors }
            onSubmit={ onSubmit }
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  } 
}

export default New;