import { FC, Dispatch } from 'react';
import { Toast } from 'react-bootstrap';
import style from './Toast.module.scss';

type ErrorToastProps = {
  message?: string | null;
  isDisplayToast: boolean;
  setIsDisplayToast: Dispatch<React.SetStateAction<boolean>>;
}

export const ErrorToast: FC<ErrorToastProps> = ({ 
  message = 'サーバーエラーが発生しました。',
  isDisplayToast,
  setIsDisplayToast
}) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Toast
        onClose={() => setIsDisplayToast(false)}
        show={isDisplayToast}
        className={style.toast}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">{message}</strong>
        </Toast.Header>
      </Toast>
    </div>
  );
}
