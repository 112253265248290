import Index from 'pages/salesForces/index';

import {
  indexSalesForcesPath,
} from './path';

export const salesForcesRoutes = [
  {
    name: "Salesforce",
    path: indexSalesForcesPath,
    component: Index,
    auth: true,
    visible: true
  }
]

