import { FC, useState } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import Table from 'components/Table';
import PageTitle from 'components/PageTitle';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { newCompaniesPath, showCompaniesPathCreator } from 'routes/companies/path';
import { useFetchCompanies } from 'hooks/api/admin/companies/useFetchCompanies'
import Forbidden from 'pages/forbidden';

const Index: FC = () => {
  const history = useHistory();
  const { data, status } = useFetchCompanies(true);

  if (status && status == 403) {
    return <Forbidden />
  }

  return (
    <Layout>
      <PageTitle title="企業一覧">
        <Button onClick={() => history.push(newCompaniesPath)}>新規追加</Button>
      </PageTitle>

      <Panel>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>企業名</th>
              <th>キャンペーン数</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            { data?.companies && data.companies.map(company => (
              <tr key={company.id}>
                <td className="align-middle" >{company.id}</td>
                <td className="align-middle" >{company.name}</td>
                <td className="align-middle" >{company.campaignSize || 0}</td>
                <td className="align-middle" >
                  <Button variant='dark' onClick={() => history.push(`/companies/${company.id}/edit`)}>編集</Button>
                  <Button variant="outline-info" className="ml-3" onClick={() => history.push(showCompaniesPathCreator(company.id))}>詳細</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Index;