import { get } from 'libs/fetch';

export const indexPath = 'api/v1/universities';

export type University = {
  id: number;
  name: string;
  name_kana: string;
  degree: string;
  searchKey: string;
}

export const getUniversities = async (callback: (responseData: University[]) => void) => {
  const res = await get('api/v1/universities');
  const body = await res.json();

  const responseData: University[] = body.universities;
  callback(responseData)
}