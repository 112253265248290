export const createPath = '/api/v1/admin/entry_spreadsheets';
export const showPath = (id: string | number): string => `api/v1/admin/entry_spreadsheets/${id}`;
export const updatePath = (id: string | number): string => `api/v1/admin/entry_spreadsheets/${id}`;

export type EntrySpreadsheet = {
  id: number;
  isSendingSpreadsheet: boolean;
  spreadsheetId: string;
  spreadsheetWorksheetName: string;
  campaignId: string | number;
}

/* patch request */
export type RequestData = {
  isSendingSpreadsheet: boolean;
  spreadsheetId: string;
  spreadsheetWorksheetName: string;
  campaignId: string | number;
}

export type Response = {
  id: number;
}

export type Errors = {
  isSendingSpreadsheet?: string[];
  spreadsheetId?: string[];
  spreadsheetWorksheetName?: string[];
  campaignId?: string[];
}
