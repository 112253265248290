import { get, post, patch } from 'libs/fetch';

export type PermissionGroup = {
  id: number,
  name: string,
  description: string,
  authorizations?: Authorization[],
}

export type Authorization = {
  id: number,
  controller: string,
  action: string,
}

export type RequestData = {
  name: string;
  description: string;
  authorizations?: {
    controller: string;
    action: string;
  }[],
}

export type Response = {
  permissionGroup: PermissionGroup;
}

export type Errors = {
  name: string[];
  description: string[];
  authorizations: string[];
}

export const RequestTemplate = {
  name: '',
  description: '',
  authorizations: [],
}

export const ErrorsTemplate = {
  name: [],
  description: [],
  authorizations: [],
}

export const getPermissionGroups = async (
  callback: (responseData: PermissionGroup[]) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get('api/v1/admin/permission_groups');
  const body = await res.json();

  if (res.status == 200) {
    const responseData: PermissionGroup[] = body;
    callback(responseData)
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

export const createPermissionGroup = async (
  data: RequestData,
  callback: (responseData: Response) => void,
  errCallback: (errors: Errors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await post('api/v1/admin/permission_groups', { permission_group: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: Response = body.permissionGroup;
    callback(responseData);
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}

export const showPermissionGroup = async (
  id: number | string,
  callback: (permissionGroup: PermissionGroup) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get(`api/v1/admin/permission_groups/${id}`);
  const body = await res.json();

  if (res.status == 200) {
    const permissionGroup: PermissionGroup = body.permissionGroup;
    callback(permissionGroup);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}

export const updatePermissionGroup = async (
  id: number | string,
  data: RequestData,
  callback: (responseData: Response) => void,
  errCallback: (errors: Errors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await patch(`api/v1/admin/permission_groups/${id}`, { permission_group: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: Response = body.permissionGroup;
    callback(responseData);
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}


