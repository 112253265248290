import { FC, ReactNode } from 'react';
import style from './Panel.module.scss';

type PanelProps = {
  children: ReactNode;
  className?: string;
};

const Panel: FC<PanelProps> = (props) => {
  const { children, className } = props;

  return (
    <div className={ `${className}` }>
      <div className={ `${style.panel}` }>
        { children }
      </div>
    </div>
  );
}

Panel.defaultProps = {
  className: ''
}

export default Panel;