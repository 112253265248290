import { companiesRoutes } from './companies';
import { educationalBackgroundGroupsRoutes } from './educationalBackgroundGroups';
import { tagsRoutes } from './tags';
import { areasRoutes } from './areas';
import { adminsRoutes } from './admins';
import { usersRoutes } from './users';
import { permissionGroupsRoutes } from './permissionGroups';
import { salesForcesRoutes } from './salesForces';

export const routes = [
    ...companiesRoutes,
    ...educationalBackgroundGroupsRoutes,
    ...tagsRoutes,
    ...areasRoutes,
    ...adminsRoutes,
    ...usersRoutes,
    ...permissionGroupsRoutes,
    ...salesForcesRoutes,
]
