import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Item } from 'components/Selectize';
import { useHistory } from 'react-router';
import { showAreasPathCreator } from 'routes/areas/path';
import { index, Prefecture } from 'libs/apiClient/prefectures';
import { create, CreateData, CreateErrors, CreateResponse } from 'libs/apiClient/admin/areas';
import AreaForm from '../components/AreaForm';
import Forbidden from 'pages/forbidden';

const New: FC = () => {
  const history = useHistory()
  const [createData, setCreateData] = useState<CreateData>({ name: '', prefectureIds: [] });
  const [errors, setErrors] = useState<CreateErrors>({ name: [], prefectureIds: [] });
  const [prefectures, setPrefectures] = useState<Item<number>[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false)

  useEffect(() => {
    index((res: Prefecture[]) => {
      setPrefectures(res.map(prefecture => {
        return {
          id: prefecture.id,
          label: prefecture.name,
          value: prefecture.id,
          isDisplay: true,
          isSelected: false
        }
      }))
    })
  }, [])

  const onSubmit = async () => {
    const callback = (res: CreateResponse) => {
      history.push(showAreasPathCreator(res.id))
    }

    const errCallback = (err: CreateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await create(createData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="エリア登録" />
        <Panel className="col-6">
          <AreaForm
            formData={createData}
            setFormData={setCreateData}
            errors={errors}
            prefectures={prefectures}
            setPrefectures={setPrefectures}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default New;