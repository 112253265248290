import { useFetch } from 'hooks/useFetch'

export type Prefecture = {
  id: string,
  name: string,
  nameKana: string
}

type ResponseType = {
  prefectures: Prefecture[]
}

export const useFetchPrefectures = (isImmediate = false) => {
  const path = 'api/v1/prefectures'
  return useFetch<ResponseType>(path, isImmediate)
}
