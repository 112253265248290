import { get } from 'libs/fetch'
import { useCallback, useState } from 'react';

type UniversityFaculty = {
  id: number;
  name: string;
  universityId: string;
}

export const useFetchUniversityFaculties = () => {
  const [universityFaculties, setUniversities] = useState<UniversityFaculty[]>([])

  const fetchUniversityFaculties = useCallback(async (universityId: string | number) => {
    const response = await get(`/api/v1/university_faculties?university_id=${universityId}`)

    if (response.ok) {
      const body: { universityFaculties: UniversityFaculty[] } = await response.json();
      setUniversities(body.universityFaculties);
      return
    }

    const error: Error = new Error('エラーになりました');
    throw error;
  }, []);

  return { universityFaculties, fetchUniversityFaculties }
};
