import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import { RouteComponentProps, useHistory } from 'react-router';
import { show, Tag } from 'libs/apiClient/admin/tags';
import { editTagsPathCreator } from 'routes/tags/path';
import { Button } from 'react-bootstrap';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Show: FC<urlProps> = (props: urlProps) => {
  const history = useHistory()
  const [tag, setTag] = useState<Tag>()
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const tagId = props.match.params.id

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }

    show(tagId, (responseData: Tag) => {
      setTag(responseData);
    }, forbiddenCallback)
  }, []);

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="タグ詳細">
          <Button variant="primary" onClick={() => history.push(editTagsPathCreator(tagId))}>編集</Button>
        </PageTitle>
        <Panel className='pb-2'>
          <PanelTitle title='基本情報' />
          <p>id: {tag?.id}</p>
          <p>タグ名: {tag?.name}</p>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Show;