
import { get, post } from 'libs/fetch';

export const showPath = (id: string | number) => `api/v1/admin/companies/${id}`
export const updatePath = (id: string | number) => `api/v1/admin/companies/${id}`

export type RequestData = {
  name: string;
}

export type Errors = {
  name: string[];
}

export type Response = {
  id: number | string;
}

/* get request */
export type Client = {
  id: number,
  email: string
}

export type Company = {
  id: number
  name: string,
  clients: Client[],
  campaigns: number
}

export const index = async (
  callback: (responseData: Company[]) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get('api/v1/admin/companies');
  const body = await res.json();

  if (res.status === 200) {
    const responseData: Company[] = body.companies;
    callback(responseData)
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

export const show = async (
  id: string | number,
  callback: (responseData: Company) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get(`api/v1/admin/companies/${id}`);
  const body = await res.json();

  if (res.status === 200) {
    const responseData: Company = body.company;
    callback(responseData)
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}



/* post request */
export type CreateData = {
  name: string;
}

export type CreateErrors = {
  name: string[];
}

export type CreateResponse = {
  id: number;
  name: string;
}

export const create = async (
  data: CreateData,
  callback: (responseData: CreateResponse) => void,
  errCallback: (errors: CreateErrors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await post('/api/v1/admin/companies', { company: data })
  const body = await res.json();

  if (res.status == 200) {
    const responseData = body.company;
    callback(responseData)
  } else if (res.status == 400) {
    const errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }

  // 500エラー時の対応
}