import { FC, ReactNode } from 'react';

type PageTitleProps = {
  title: string;
  children?: ReactNode;
  className?: string;
}

const PageTitle: FC<PageTitleProps> = (props) => {
  const { title, children } = props;

  return (
    <div className="d-flex mb-3">
      <h3 className="mr-auto mb-0">{ title }</h3>
      { children }
    </div>
  )
}

PageTitle.defaultProps = {
  className: ''
}

export default PageTitle;