import { FC, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { CreativeImage, indexPath, createPath, RequestData, Response, Errors } from 'libs/apiClient/admin/creativeImages';
import { get, createFormData } from 'libs/apiClient';
import { nanoid } from 'nanoid';
import Panel from 'components/Panel';
import style from './ImagePicker.module.scss';
import { useEffect } from 'react';

const ImagePicker: FC = (props) => {
  const [thumbnail, setThumbnail] = useState<string|undefined>();
  const [formData, setFormData] = useState<RequestData>({
    image: null
  });
  const [errors, setErrors] = useState<Errors>({});
  const [key, setKey] = useState<string>(nanoid(8));
  const [creativeImages, setCreativeImages] = useState<CreativeImage[]>();

  useEffect(() => {
    let isMount = true;
    get(indexPath, 'creativeImages', setCreativeImages);
    return () => { isMount = false };
  }, [])

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFormData = { ...formData }
      newFormData.image = e.target.files[0];
      setFormData(newFormData)

      // サムネイルの読み込み
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e?.target?.result) {
          setThumbnail(e.target.result as string);
        }
      };
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onUrlCopy = (url: string) => {
    navigator.clipboard.writeText(url).then(_e => {
      alert(`次のURLをコピーしました。\n${url}`);
    });
  }

  const onSubmit = () => {
    const callback = (res: Response) => {
      setThumbnail(undefined);
      setFormData({ image: null });
      onUrlCopy(res.image.url);
      setKey(nanoid(8));
      get(indexPath, 'creativeImages', setCreativeImages);
    }

    const errCallback = (res: Errors) => {
      setErrors(res);
    }

    const requestData = new FormData();
    requestData.append('creative_image[image]', formData.image ?? '');

    createFormData(createPath, requestData, 'creativeImage', callback, errCallback);
  }

  return (
    <div>
      <Form className='mb-4' noValidate>
        <Form.Group controlId='image'>
          <Form.Label>画像</Form.Label>
          <Form.Control
            key={ `file-form-${key}`}
            type="file"
            placeholder="アップロードする画像を選択してください"
            accept=".jpg,.jpeg,.png"
            name="image"
            onChange={ onFileChange }
            isInvalid={ errors.image && errors.image?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors.image?.map((err, i) => <p key={ `error-image-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
          { thumbnail && <Image className='col-3 mt-2' src={ thumbnail } thumbnail /> }
        </Form.Group>

        <Button type="button" variant='outline-success' onClick={ _e => onSubmit() }>アップロード</Button>
      </Form>
      <Panel className={ `${style.container}` }>
        {
          creativeImages?.map((creativeImage: CreativeImage) => {
            return (
              <Image
                onClick={ _e => onUrlCopy(creativeImage.image.url) }
                className='col-3 mt-2'
                src={ creativeImage.image.url }
              />
            )
          })
        }
      </Panel>
    </div>
  )
}

export default ImagePicker;