import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import Table from 'components/Table';
import PageTitle from 'components/PageTitle';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { newAreasPath, showAreasPathCreator, editAreasPathCreator } from 'routes/areas/path';
import { index, Area } from 'libs/apiClient/admin/areas';
import Forbidden from 'pages/forbidden';

const Index: FC = () => {
  const history = useHistory();
  const [areas, setAreas] = useState<Area[]>([]);
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }
    index(setAreas, forbiddenCallback);
  }, []);

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="エリア一覧">
          <Button onClick={() => history.push(newAreasPath)}>新規追加</Button>
        </PageTitle>

        <Panel>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>エリア名</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {
                areas.map(area => {
                  return (
                    <tr key={area.id}>
                      <td className="align-middle" >{area.id}</td>
                      <td className="align-middle" >{area.name}</td>
                      <td className="align-middle" >
                        <Button variant='dark' onClick={() => history.push(editAreasPathCreator(area.id))}>編集</Button>
                        <Button variant="outline-info" className="ml-3" onClick={() => history.push(showAreasPathCreator(area.id))}>詳細</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Index;