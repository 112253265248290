import { useState } from 'react'
import { post } from 'libs/fetch'

export const usePost = <RequestBody>(
  path: string,
) => {
  const [loading, setLoading] = useState<boolean>(false)

  const create = async (requestBody: RequestBody) => {
    setLoading(true)
    const response = await post<RequestBody>(path, requestBody)
    setLoading(false)

    return response
  }

  return { loading, create }
}