import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Item } from 'components/Selectize';
import { useHistory } from 'react-router';
import { showEducationalBackgroundGroupsPathCreator } from 'routes/educationalBackgroundGroups/path';
import { getUniversities, University } from 'libs/apiClient/universities';
import { create, CreateData, CreateErrors, CreateResponse } from 'libs/apiClient/admin/educationalBackgroundGroups';
import EducationalBackgroundGroupForm from '../components/EducationalBackgroundGroupForm';
import Forbidden from 'pages/forbidden';

type Universities = {
  [key: string]: number[];
} & Object;

const New: FC = () => {
  const history = useHistory()
  const [createData, setCreateData] = useState<CreateData>({ name: '', universityIds: [] });
  const [errors, setErrors] = useState<CreateErrors>({ name: [], universityIds: [] });
  const [universities, setUniversities] = useState<Item<number[]>[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const shape = (res: University[]) => {

      const data: Universities = {};
      console.log(res);

      res.forEach((university: University) => {
        if (data.hasOwnProperty(university.name)) {
          data[university.name].push(university.id);
        } else {
          data[university.name] = [university.id];
        }
      })

      const universityList = Object.keys(data).map((key, i) => {
        return {
          id: i + 1,
          label: key,
          value: data[key],
          isDisplay: true,
          isSelected: false
        }
      });
      setUniversities(universityList);
    }

    getUniversities(shape);
  }, [])

  const onSubmit = async () => {
    const callback = (res: CreateResponse) => {
      history.push(showEducationalBackgroundGroupsPathCreator(res.id))
    }

    const errCallback = (err: CreateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await create(createData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="学歴グループ登録" />
        <Panel className="col-6">
          <EducationalBackgroundGroupForm
            formData={createData}
            setFormData={setCreateData}
            errors={errors}
            universities={universities}
            setUniversities={setUniversities}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default New;