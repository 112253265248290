export const indexPath = (entryFormId: string | number | null = null): string => {
  const queryParams = entryFormId ? new URLSearchParams([['entry_form_id', String(entryFormId)]]) : '';
  return `api/v1/admin/custom_form_items?${queryParams}`;
}
export const createPath = 'api/v1/admin/custom_form_items';
export const showPath = (id: string | number): string => `api/v1/admin/custom_form_items/${id}`;
export const updatePath = (id: string | number): string => `api/v1/admin/custom_form_items/${id}`;
export const destroyPath = (id: string | number): string => `api/v1/admin/custom_form_items/${id}`;

export type ItemType = 'text_field' | 'textarea' | 'radio' | 'checkbox' | 'selectbox' | 'agreement' | 'datetime_field' | 'full_address';

export type CustomFormItem = {
  id: number | string;
  fieldContext: ItemType;
  isRequired: boolean;
  itemDetail: MultipleItemType | TextFieldType | TextareaType | {}
  label: string;
  priority: number;
  entryFormId: number | string;

}

export type MultipleItem = {
  label: string;
  value: string;
  isDefault: boolean;
}

export type MultipleItemType = {
  items: MultipleItem[];
}

export type TextFieldType = {
  type: 'text' | 'number'  | 'email' | 'telephone' | 'url';
  placeholder?: string;
  maxlength?: string;
  minlength?: string;
}

export type TextareaType = {
  placeholder?: string;
  maxlength?: string;
  minlength?: string;
}

export type RequestData = {
  fieldContext: ItemType;
  isRequired: boolean,
  label: string,
  priority: number,
  entryFormId: number | string,
  itemDetail: { items: MultipleItemType[] } | TextFieldType | TextareaType | {}
}

export type Errors = {
  fieldContext?: string[];
  isRequired?: string[];
  label?: string[];
  priority?: string[];
  entryFormId?: string[];
  itemDetail?: string[];
  itemDetailTextFieldType?: string[];
  itemDetailAgreementLabel?: string[];
  itemDetailSelectboxItems?: string[];
  itemDetailRadioItems?: string[];
  itemDetailCheckboxItems?: string[];
}

export type ResponseData = {
  id: number | string;
}
