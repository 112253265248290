import { FC, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { showPath, createPath, updatePath, ThanksMail, RequestData, Response, Errors } from 'libs/apiClient/admin/thanksMails';
import { show, create, update } from 'libs/apiClient';

type Props = {
  thanksMail: ThanksMail | undefined;
  campaignId: string | number;
}

const ThanksMailForm: FC<Props> = (props) => {
  const { thanksMail, campaignId } = props;
  const [formData, setFormData] = useState<RequestData>({
    title: thanksMail?.title || '',
    body: thanksMail?.body || '',
    // htmlBody: thanksMail?.body || '',
    campaignId: campaignId
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isUpdate, setIsUpdate] = useState<boolean>(Boolean(thanksMail?.id))
  const [thanksMailId, setThanksMailId] = useState<string|number|undefined>(thanksMail?.id)

  const onChange = (value: string, name: keyof RequestData) => {
    const newFormData = { ...formData }
    newFormData[name] = value;
    setFormData(newFormData);
  }

  const requestCallback = (id: string | number) => {
    show(showPath(id), 'thanksMail', (response: ThanksMail) => {
      setFormData({
        title: response.title,
        body: response.body,
        htmlBody: response.htmlBody,
        campaignId: campaignId,
      })
    })
    setErrors({})
  }

  const onCreate = () => {
    const callback = (response: Response) => {
      requestCallback(response.id)
      setIsUpdate(true)
      setThanksMailId(response.id)
      alert('サンクスメールの作成に成功しました。');
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    create(createPath, { thanksMail: formData }, 'thanksMail', callback, errCallback);
  }

  const onUpdate = () => {
    const callback = (response: Response) => {
      requestCallback(response.id)
      alert('サンクスメールの更新に成功しました。');
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    if (thanksMailId) {
      update(updatePath(thanksMailId), { thanksMail: formData }, 'thanksMail', callback, errCallback);
    }
  }

  return (
    <Form noValidate>
      <Form.Group controlId='thanksMailTitle'>
        <Form.Label>メールタイトル</Form.Label>
        <Form.Control
          type='text'
          name="title"
          defaultValue={ formData.title }
          onChange={ e => onChange(e.target.value, 'title') }
          isInvalid={ errors.title && errors.title?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.title?.map((err, i) => <p key={ `error-name-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='thanksMailBody'>
        <Form.Label>本文(textメール)</Form.Label>
        <Form.Control
          as="textarea"
          name="body"
          defaultValue={ formData.body }
          onChange={ e => onChange(e.target.value, 'body') }
          isInvalid={ errors.body && errors.body?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.body?.map((err, i) => <p key={ `error-body-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      {/* <Form.Group controlId='thanksMailHtmlBody'>
        <Form.Label>本文(htmlメール)</Form.Label>
        <Form.Control
          as="textarea"
          name="htmlBody"
          defaultValue={ formData.htmlBody }
          onChange={ e => onChange(e.target.value, 'htmlBody') }
          isInvalid={ errors.htmlBody?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.htmlBody?.map((err, i) => <p key={ `error-htmlBody-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group> */}

      <Button type="button" variant='outline-success' onClick={ isUpdate ? onUpdate : onCreate }>
        { isUpdate ? '更新' : '作成' }
      </Button>
    </Form>
  )
}

export default ThanksMailForm;