import { useState, useEffect } from 'react'
import { get } from 'libs/fetch'

export const useFetch = <ResponseBody>(
  path: string,
  isImmediate: boolean = false
) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ResponseBody>()
  const [status, setStatus] = useState<number>()

  const getter = async () => {
    setLoading(true)

    const response = await get(path)
    const body: ResponseBody = await response.json()

    setStatus(response.status)
    setData(body)
    setLoading(false)
  }

  useEffect(() => {
    if (isImmediate) {
      getter()
    }
  }, [])

  return { loading, data, status, getter }
}