import { FC, Dispatch } from 'react';
import Selectize, { Item } from 'components/Selectize';
import { Button, Form } from 'react-bootstrap';
import {
  CreateData,
  CreateErrors,
  // UpdateData,
  // UpdateErrors
} from 'libs/apiClient/admin/areas';

type FormProps = {
  // formData: CreateData | UpdateData;
  // setFormData: Dispatch<React.SetStateAction<CreateData | UpdateData>>;
  // errors: CreateErrors | UpdateErrors;
  formData: CreateData
  setFormData: Dispatch<React.SetStateAction<CreateData>>;
  errors: CreateErrors;
  prefectures: Item<number>[];
  setPrefectures: Dispatch<React.SetStateAction<Item<number>[]>>;
  onSubmit: () => void;
}

const AreaForm: FC<FormProps>= (props) => {
  const {
    formData,
    setFormData,
    errors,
    prefectures,
    setPrefectures,
    onSubmit
  } = props;

  const onChange = (value: string, key: 'name'): void => {
    const data = { ...formData }
    data[key] = value;

    setFormData(data);
  }

  const setPrefectureIds = (item: Item<number>): void => {
    const data = { ...formData }
    data.prefectureIds.push(item.value);
    setFormData(data);
  }

  const removePrefectureIds = (item: Item<number>): void => {
    const data = { ...formData }
    data.prefectureIds = data.prefectureIds.filter(prefectureId => !(prefectureId == item.value))
    setFormData(data);
  }

  return (
    <Form noValidate>
      <Form.Group controlId='formBasicName'>
        <Form.Label>エリア名</Form.Label>
        <Form.Control
          type='text'
          name="name"
          onChange={ e => onChange(e.currentTarget.value, "name") }
          defaultValue={ formData.name }
          isInvalid={ errors.name?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.name?.map((err, i) => <p key={ `error-name-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='formBasicName'>
        <Form.Label>都道府県</Form.Label>
        <Selectize
          id={ 'prefectures' }
          items={ prefectures }
          setItems={ setPrefectures }
          onRemove={ removePrefectureIds }
          onSelect={ setPrefectureIds }
          placeholder={ '都道府県を入力してください' }
          isInvalid={ errors.prefectureIds?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.prefectureIds?.map((err, i) => <p key={ `error-prefectureIds-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="button" onClick={ onSubmit } variant='outline-success'>
        登録
      </Button>
    </Form>
  )
}

export default AreaForm;
