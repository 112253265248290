import { FC, useState } from 'react';
import Datetime from 'react-datetime';
import { Form } from 'react-bootstrap';
import moment from 'moment';

type Props = {
  className?: string;
  isInvalid?: boolean;
  name?: string;
  onChange: (value: string) => void;
  format?: string;
  value?: string;
}

const DateTime: FC<Props> = (props) => {
  const { className, isInvalid, onChange, name, format, value } = props;
  const [formValue, setFormValue] = useState<string | moment.Moment | Date | undefined>(value);

  const classNames = [];

  if (className) {
    classNames.push(className);
  }

  if (isInvalid) {
    classNames.push('is-invalid');
  }

  const renderInput = (props: any) => {
    return (
      <Form.Control
        { ...props }
        type='text'
        name={ name }
        isInvalid={ isInvalid }
      />
    )
  }

  const onDateChange = (value: string | moment.Moment): void => {
    const datetime = typeof value == 'string' ? value : value.format(format);
    console.log(format, datetime)
    onChange(datetime);
    setFormValue(datetime);
  }

  return(
    <Datetime
      className={ classNames.join(' ') }
      locale='ja'
      renderInput={ renderInput }
      onChange={ onDateChange }
      initialValue={ formValue }
    />
  )
}

DateTime.defaultProps = {
  format: "YYYY/MM/DD HH:mm"
}

export default DateTime