import Index from 'pages/areas/index';
import New from 'pages/areas/new';
import Show from 'pages/areas/show';
import Edit from 'pages/areas/edit';


import {
  indexAreasPath,
  newAreasPath,
  showAreasPath,
  editAreasPath
} from './path';

export const areasRoutes = [
  {
    name: "エリア",
    path: indexAreasPath,
    component: Index,
    auth: true,
    visible: true
  },
  {
    name: "エリア作成",
    path: newAreasPath,
    component: New,
    auth: true
  },
  {
    name: "エリア詳細",
    path: showAreasPath,
    component: Show,
    auth: true
  },
  {
    name: "エリア編集",
    path: editAreasPath,
    component: Edit,
    auth: true
  }
]
