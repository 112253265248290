import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "destyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/base.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
