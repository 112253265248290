import React from 'react';
import Layout from 'layouts/Layout';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Top = () => {
  return(
    <Layout>
      <h1>#Top Page</h1>
      <ul>
        <li><Link to='/admins/new'>admin new</Link></li>
      </ul>
    </Layout>
  )
}

export default Top;
