import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import {
  updatePath,
  RequestData,
  Errors,
  Response
} from 'libs/apiClient/admin/password';
import { update } from 'libs/apiClient';
import { useLocation } from 'react-router-dom';

type FormDataKey = 'password' | 'passwordConfirmation';

const ResetPassword: React.FC = (props) => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const resetPasswordToken = query.get('reset_password_token');
  const formDataTemplate = {
    administrator: { resetPasswordToken: resetPasswordToken, password: '', passwordConfirmation: '' }
  }
  const history = useHistory();
  const [formData, setFormData] = useState(formDataTemplate);
  const [errors, setErrors] = useState<Errors>({});
  console.log(errors)

  const onSubmit = async () => {
    const callback = (res: Response) => {
      history.push('/')
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
      console.log(err)
    }

    await update(updatePath(), formData, 'password', callback, errCallback);
  }

  const onChange = (value: string, key: FormDataKey): void => {
    const data = { ...formData }
    data.administrator[key] = value;

    setFormData(data);
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={12} md={6}>
          <h1 className='mt-5'>#パスワード設定</h1>
          <Form className='mt-5 p-3 border border-secondary rounded'>
            <Form.Group controlId='formBasicPassword'>
              <Form.Label>パスワード</Form.Label>
              <Form.Control
                type='password'
                placeholder='enter password'
                onChange={e => onChange(e.currentTarget.value, 'password')}
                isInvalid={errors.password && errors.password.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password?.map((err, i) => <p key={`error-password-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formBasicPasswordConfirmation'>
              <Form.Label>パスワード確認</Form.Label>
              <Form.Control
                type='password'
                placeholder='enter password confirmation'
                onChange={e => onChange(e.currentTarget.value, 'passwordConfirmation')}
                isInvalid={
                  (errors.passwordConfirmation && errors.passwordConfirmation.length > 0)
                  || (errors.resetPasswordToken && errors.resetPasswordToken.length > 0)
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.passwordConfirmation?.map((err, i) => <p key={`error-password-confirmation-${i}`}>{err}</p>)}
                {errors.resetPasswordToken?.map((err, i) => <p key={`error-password-token-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant='outline-primary' onClick={onSubmit}>保存</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ResetPassword;
