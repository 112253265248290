import { FC, useState } from 'react'
import { useCreateSchedules, CreateRequestBody, CreateErrorResponse } from 'hooks/api/admin/schedules/useCreateSchedule'
import { ScheduleForm } from './ScheduleForm'
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';

type CreateScheduleFormProps = {
  campaignId: string
  onClose: () => void
  reload: () => void
}

export type CreateFormData = CreateRequestBody['schedule']
export type CreateFormError = CreateErrorResponse['errors']

export const CreateForm: FC<CreateScheduleFormProps> = ({
  campaignId,
  onClose,
  reload
}) => {
  const defaultValues: CreateFormData = {
    type: 'OnlineSchedule',
    onlineText: '',
    prefectureId: '',
    postcode: '',
    city: '',
    building: '',
    station: '',
    isSchedule: false,
    noScheduleText: '',
    startAt: '',
    endAt: '',
    isPublished: false,
    publicStartAt: '',
    publicEndAt: '',
    participantLimit: undefined,
    remarks: '',
    areaId: '',
    targetPrefectureIds: [],
    targetSpecialityClassifications: []
  }

  const [formData, setFormData] = useState<CreateFormData>(defaultValues)
  const [errors, setErrors] = useState<CreateFormError>({})
  const { create } = useCreateSchedules(campaignId)

  const onChangeValue = (values: CreateFormData) => {
    setFormData(values)
  }

  const onSubmit = async () => {
    const response = await create({ schedule: formData });
    const responseBody = await response.json();

    switch(response.status) {
      case 200:
        onClose()
        reload()
        return
      case 400:
        const errors: CreateFormError = responseBody.errors;
        setErrors(errors);
        return
    }
  }

  return (
    <Panel>
      <PanelTitle title={ 'スケジュール作成' }/>
      <ScheduleForm
        errors={errors}
        formData={formData}
        setFormData={onChangeValue}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Panel>
  )
}