import Index from 'pages/users/entries/index';

import {
  indexUserEntriesPath,
} from './path';

export const userEntriesRoutes = [
  {
    name: "エントリー",
    path: indexUserEntriesPath,
    component: Index,
    auth: true,
    visible: true
  }
]
